import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/telegram";
import { message } from "antd";

const initialState = {
    loading: false,
    error: false,
    autoPost: [],
    autoPostRow: null,
    urlNewDomains: [],
    UserIdData: [],
    UserSettingsData: [],
    botUsersData: [],
    userSettingRow: null
}

export const fetchUrlNewDomain = createAsyncThunk(
    "/telegram/geturldomains",
    async (page, { rejectWithValue }) => {
        try {
            const resp = await api.fetchUrlNewDomain(page);
            if (resp.status === 200) {
                return resp.data;
            } else {
                message.error(resp.data.message ? resp.data.message : 'Error Fetching!');
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

export const fetchAutoPost = createAsyncThunk(
    "/telegram/gettelegramautopost",
    async (page, { rejectWithValue }) => {
        try {
            const resp = await api.fetchAutopost(page);
            if (resp.status === 200) {
                return resp.data;
            } else {
                message.error(resp.data.message ? resp.data.message : 'Error Fetching!');
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

export const updateTelegramAutoPost = createAsyncThunk(
    "telegram/updatetelegramautopost",
    async (query, { rejectWithValue }) => {
        try {
            const resp = await api.updateTelegramAutoPost(query);
            if (resp.status === 200) {
                message.success(
                    resp.data.message
                        ? resp.data.message
                        : "Updated Successfully!"
                );
                // return resp.data.data;
            } else {
                message.error(
                    resp.data.message
                        ? resp.data.message
                        : "Not Updated OR Something went Wrong!"
                );
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            message.error("Error Updating!")
            rejectWithValue(error.resp.data);
        }
    }
);

export const updateUserSetting = createAsyncThunk(
    "telegram/updateusersetting",
    async (query, { rejectWithValue }) => {
        try {
            const resp = await api.updateUserSetting(query);
            if (resp.status === 200) {
                message.success(
                    resp.data.message
                        ? resp.data.message
                        : "Updated Successfully!"
                );
            } else {
                message.error(
                    resp.data.message
                        ? resp.data.message
                        : "Not Updated OR Something went Wrong!"
                );
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            message.error("Error Updating!")
            rejectWithValue(error.resp.data);
        }
    }
);

export const updateUrlNewDomains = createAsyncThunk(
    "telegram/updateurldomains",
    async (query, { rejectWithValue }) => {
        try {
            const resp = await api.updateUrlNewDomain(query);
            if (resp.status === 200) {
                message.success(
                    resp.data.message
                        ? resp.data.message
                        : "Updated Successfully!"
                );
                // return resp.data.data;
            } else {
                message.error(
                    resp.data.message
                        ? resp.data.message
                        : "Not Updated OR Something went Wrong!"
                );
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

export const createAutoPost = createAsyncThunk(
    "telegram/inserttelegramautopost",
    async (formData, { rejectWithValue }) => {
        try {
            const resp = await api.createAutoPost(formData);
            if (resp.status === 200) {
                message.success(
                    resp.data.message ? resp.data.message : "Created Successfully!"
                );
            } else {
                message.error(
                    resp.data.message
                        ? resp.data.message
                        : "Error inserting!"
                );
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            message.error("Error inserting!");
            rejectWithValue(error.resp.data);
        }
    }
);

export const fetchUserIdMapping = createAsyncThunk(
    "/telegram/geetuidchannelmapping",
    async (page, { rejectWithValue }) => {
        try {
            const resp = await api.fetchUserIdMapping(page);
            if (resp.status === 200) {
                return resp.data;
            } else {
                message.error(resp.data.message ? resp.data.message : 'Error Fetching!');
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

export const fetchUserSettings = createAsyncThunk(
    "/telegram/getusersetting",
    async (_, { rejectWithValue }) => {
        try {
            const resp = await api.fetchUserSettings();
            if (resp.status === 200) {
                return resp.data.data;
            } else {
                message.error(resp.data.message ? resp.data.message : 'Error Fetching!');
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

export const fetchTelegramBotUsers = createAsyncThunk(
    "/telegram/getbotusers",
    async (page, { rejectWithValue }) => {
        try {
            const resp = await api.fetchTelegramBotUsers(page);
            if (resp.status === 200) {
                return resp.data;
            } else {
                message.error(resp.data.message ? resp.data.message : 'Error Fetching!');
                return rejectWithValue(resp.data);
            }
        } catch (error) {
            rejectWithValue(error.resp.data);
        }
    }
);

const telegramSlice = createSlice({
    name: "telegram",
    initialState,
    reducers: {
        getAutoPostRow: (state, action) => {
            state.autoPostRow = action.payload;
        },
        clearAutoPostRow: (state) => {
            state.autoPostRow = null
        },
        getUserSettingRow: (state, action) => {
            state.userSettingRow = action.payload
        },
        clearUserSettingRow: (state) => {
            state.userSettingRow = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAutoPost.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAutoPost.fulfilled, (state, { payload }) => {
            state.autoPost = {
                autoPost: payload?.data,
                count: payload?.count
            };
            state.loading = false;
        });
        builder.addCase(fetchAutoPost.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        builder.addCase(fetchUrlNewDomain.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUrlNewDomain.fulfilled, (state, { payload }) => {
            state.urlNewDomains = {
                urlNewDomains: payload?.data,
                count: payload?.count
            };
            state.loading = false;
        });
        builder.addCase(fetchUrlNewDomain.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        builder.addCase(updateTelegramAutoPost.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateTelegramAutoPost.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateTelegramAutoPost.rejected, (state, action) => {
            state.error = action.payload;
        });

        builder.addCase(fetchUserSettings.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserSettings.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.UserSettingsData = payload;
        });
        builder.addCase(fetchUserSettings.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        builder.addCase(fetchUserIdMapping.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserIdMapping.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.UserIdData = {
                UserIdData: payload.data,
                count: payload.count
            };
        });
        builder.addCase(fetchUserIdMapping.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        builder.addCase(fetchTelegramBotUsers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTelegramBotUsers.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.botUsersData = {
                botUsersData: payload.data,
                count: payload.count
            };
        });
        builder.addCase(fetchTelegramBotUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    }
})

export const { getAutoPostRow, clearAutoPostRow, getUserSettingRow, clearUserSettingRow } = telegramSlice.actions

export default telegramSlice.reducer