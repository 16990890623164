import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
// import { Popconfirm, message, Divider } from 'antd';
// import queryString from 'query-string';
import { fetchTelegramBotUsers } from "../../../redux/slices/telegramSlice"
import { useDispatch, useSelector } from 'react-redux';

function TelegramBotUsers() {

  const dispatch = useDispatch();

  const [page, setPage] = useState(1)

  const { botUsersData, count } = useSelector(state => state.telegram.botUsersData);

  useEffect(() => {
    dispatch(fetchTelegramBotUsers(1));
  }, []);

  useEffect(() => {
    dispatch(fetchTelegramBotUsers(page));
  }, [page]);

  const columns = [
    {
      name: "uid",
      label: "User Id",
    },
    {
      name: "name",
      label: "User Name",
    },
    {
      name: "chat_id",
      label: "Chat ID",
    },
    {
      name: "bot_id",
      label: "Bot ID",
    },
    {
      name: "verified",
      label: "Verified",
    },
    {
      name: "deleted",
      label: "Deleted",
    },
  ];

  const options = {
    selectableRows: 'none',
    rowsPerPage: 100,
    rowsPerPageOptions: [100],
    page: page - 1,
    onChangePage: (currentPage) => {
      setPage(currentPage + 1);
    },
    count,
    pagination: true,
    serverSide: true,
  }


  return (
    <>
      <MUIDataTable
        title={"Telegram Bot Users"}
        data={botUsersData && botUsersData}
        columns={columns}
        options={options}
      />

    </>
  );
}

export default TelegramBotUsers;
