import React from 'react'
import {
    Avatar,
    Box,
    Paper,
    Stack,
    Typography,
    Badge,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    tableCellClasses,
    Table,
    InputAdornment,
    IconButton,
    Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { indigo, red, green, teal } from "@mui/material/colors";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { NavLink } from "react-router-dom";
import ViewModules from './View Module';
import RoleModules from './Role Module';

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.5rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, 0)",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        // marginLeft: theme.spacing(1),
    },
    "& .AccordionSummary-head": {
        fontSize: "1.2rem",
        fontWeight: '500'
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    // borderTop: "1px solid rgba(0, 0, 0, .125)",
    "& .MuiButton-root": {
        textTransform: "initial",
    },
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters square elevation={0} {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}));

const Modules = () => {
    const [expanded, setExpanded] = React.useState("");
    const handleAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            <Accordion
                expanded={expanded === "panel2"}
                onChange={handleAccordion("panel2")}
            >
                <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                    sx={{
                        marginLeft: '-4px'
                    }}
                >
                    <Typography className="AccordionSummary-head">
                        Modules
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-around"
                    >
                        <NavLink to="/admin/modules">
                            <Button
                                color="info"
                                variant="contained"
                                size="large"
                                sx={{ backgroundColor: indigo[400] }}
                            >
                                <b>View Modules</b>
                            </Button>
                        </NavLink>
                        <NavLink to="/admin/role-modules">
                            <Button
                                color="info"
                                variant="contained"
                                size="large"
                                sx={{ backgroundColor: teal[400] }}
                            >
                                <b>Role Modules</b>
                            </Button>
                        </NavLink>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default Modules
