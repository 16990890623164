import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});

export const fetchAutopost = (params) => API.get("/telegram/gettelegramautopost", { params });
export const fetchUrlNewDomain = (params) => API.get("/telegram/geturldomains", { params });
export const updateTelegramAutoPost = (data) => API.post("/telegram/updatetelegramautopost", data);
export const updateUserSetting = (data) => API.post("/telegram/updateusersetting", data);
export const updateUrlNewDomain = (data) => API.post("/telegram/updateurldomains", data);
export const createAutoPost = (data) => API.post("/telegram/inserttelegramautopost", data);
export const fetchTelegramBotUsers = (params) => API.get("/telegram/getbotusers", { params })
export const fetchUserSettings = () => API.get("/telegram/getusersetting");
export const fetchUserIdMapping = (params) => API.get("/telegram/geetuidchannelmapping", { params });