import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from 'antd';
import * as api from "../api/modulePermissionApi";

export const modulesByUserDataPanel = createAsyncThunk(
  "auth/modulesbyuser",
  async (params = [], { rejectWithValue }) => {
    try {
      const queryString = params.map((item, index) => `param${index}=${encodeURIComponent(item)}`).join('&');
      const resp = await api.modulesByUserData(queryString);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getStoreData = createAsyncThunk(
  "/managestores/getallstores",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getStoreData();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : 'Something went Wrong!');
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    data: {
      modulesByUserData: [],
      storeData: [],
    },
    loading: false,
    error: null,
  },
  reducers: {
    fetchDataSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchDataFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(modulesByUserDataPanel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(modulesByUserDataPanel.fulfilled, (state, action) => {
      state.data.modulesByUserData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(modulesByUserDataPanel.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ==================getStoreData===============================================================================

    builder.addCase(getStoreData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getStoreData.fulfilled, (state, action) => {
      state.data.storeData = action.payload;
    });
    builder.addCase(getStoreData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { fetchDataSuccess, fetchDataFailure } = layoutSlice.actions;

export default layoutSlice.reducer;
