import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { fetchUserSettings, getUserSettingRow } from "../../../redux/slices/telegramSlice"
import { Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const ShowUserSetting = (props) => {
    const dispatch = useDispatch();
    const { UserSettingsData } = useSelector(state => state.telegram);
    useEffect(() => {
        dispatch(fetchUserSettings());
    }, []);

    const edit = (e, value) => {
        let data = {};
        data.uid = value[0]
        data.amz_aff = value[3];
        data.fk_aff = value[4];
        dispatch(getUserSettingRow(data));
        props.changeTab("updateUserSetting")
    }
    const cancel = () => {
        //console.log("Cancelled");
    }

    const columns = [
        {
            name: "uid",
            label: "User ID",
        },
        {
            name: "name",
            label: "User Name",
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) =>
                (
                    < div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Popconfirm
                            title="Edit this user's setting?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div >
                )
            }
        },
        {
            name: "amz_aff",
            label: "Amazon Affiliate",
        },
        {
            name: "fk_aff",
            label: "Flipkart Affiliate",
        },
    ];

    const options = {
        selectableRows: 'none',
        rowsPerPage: 100,
        rowsPerPageOptions: [100],
    }

    return (
        <>
            <MUIDataTable
                title={"User's Setting"}
                data={UserSettingsData && UserSettingsData}
                columns={columns}
                options={options}
            />
        </>
    );
}

export default ShowUserSetting
