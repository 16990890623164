import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { modulesByUserDataPanel } from "../../redux/slices/layoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { addModulePermission, allPermissionByUser, getAllPanelUsers } from "../../redux/slices/userRoleManagementSlice";

// import { useState } from "react";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const UserRoleManagementChange = (props) => {
  const dispatch = useDispatch()

  const { allPermissionByUser: allPermissionByUserState, userRoleManagement } = useSelector((state) => ({ ...state.userRoleManagement.data }));

  const permssionUserByModules = userRoleManagement;

  const classes = useStyles();

  let { clickedUserData } = props;
  let userId = clickedUserData && clickedUserData.id;
  let role = clickedUserData && clickedUserData.role

  const [includedPermissions, setincludedPermissions] = useState([]);
  const [excludePermissions, setexcludePermissions] = useState([]);

  useEffect(() => {
    if (allPermissionByUserState?.includedarray) {
      let arr = allPermissionByUserState?.includedarray.map((item) => item.module_id)
      setincludedPermissions(arr)
    }
    if (allPermissionByUserState?.excludedarray) {
      let arr = allPermissionByUserState?.excludedarray.map((item) => item.module_id)
      setexcludePermissions(arr)
    }
  }, [allPermissionByUserState?.includedarray, allPermissionByUserState?.excludedarray])


  const handleRemove = (item, check) => {
    if (check == 'included' && includedPermissions.includes(item)) {
      const newArr = [...includedPermissions];
      let index = newArr.indexOf(item);
      newArr.splice(index, 1);
      setincludedPermissions(newArr);
    }
    else if (excludePermissions.includes(item)) {
      const newArr = [...excludePermissions];
      let index = newArr.indexOf(item);
      newArr.splice(index, 1);
      setexcludePermissions(newArr);
    }
  };

  const handleCheckUncheck = (e, type) => {
    let checked = e.target.checked;
    const value = e.target.value;
    if (type == 'included') {
      if (checked) {
        setincludedPermissions([...includedPermissions, value]);
      } else {
        handleRemove(value, type);
      }
    }
    else {
      if (checked) {
        setexcludePermissions([...excludePermissions, value]);
      } else {
        handleRemove(value, type);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    data.user_id = userId;
    data.included_module_id_array = includedPermissions;
    data.excluded_module_id_array = excludePermissions;
    dispatch(addModulePermission(data))
      .then(() => {
        dispatch(modulesByUserDataPanel())
      })
      .then(() => {
        dispatch(allPermissionByUser({
          role: role,
          user_id: userId
        }))
      })
      .catch((error) => {
        message.error('Not Added went Wrong!');
      })
  };

  const organizePermissions = (modules) => {
    const parents = modules.filter((item) => item.parent_module_id === null);
    const children = modules.filter((item) => item.parent_module_id !== null);

    const parentChildMap = parents.map((parent) => ({
      ...parent,
      children: children.filter((child) => child.parent_module_id === parent.module_id),
    }));

    const orphanedChildren = children.filter(
      (child) => !modules.some((item) => item.module_id === child.parent_module_id)
    );

    return [
      ...parentChildMap,
      ...orphanedChildren.map((orphan) => ({
        ...orphan,
        children: [],
      })),
    ];
  };

  const handleParentCheck = (event, parent, type) => {
    const isChecked = event.target.checked;
    const updatedPermissions = isChecked
      ? [...(type === 'included' ? includedPermissions : excludePermissions), parent.module_id, ...parent.children.map((child) => child.module_id)]
      : (type === 'included' ? includedPermissions : excludePermissions).filter(
        (id) => id !== parent.module_id && !parent.children.some((child) => child.module_id === id)
      );

    if (type === 'included') {
      setincludedPermissions(updatedPermissions);
    } else {
      setexcludePermissions(updatedPermissions);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn btn-primary d-block ml-auto mt-3"
              onClick={() => props.setTab(false)}
            >
              Go Back
            </button>
            <div className=" d-flex flex-column align-items-center p-4 m-4 ">
              <div className="userid justify-content-between d-flex align-items-center w-100 mb-3">
                <p style={{ marginBottom: "0px" }}> User ID -</p>
                <p style={{ marginBottom: "0px" }}>{props.clickedUserData.id}</p>
              </div>
              <div className="userid justify-content-between d-flex align-items-center w-100 mb-3">
                <p style={{ marginBottom: "0px" }}> User Role -</p>
                <p style={{ marginBottom: "0px" }}>
                  {props.clickedUserData.role}
                </p>
              </div>
              <div className="userid justify-content-between d-flex align-items-center w-100 mb-3">
                <p style={{ marginBottom: "0px" }}>User Name -</p>
                <p style={{ marginBottom: "0px" }}>
                  {props.clickedUserData.username}
                </p>
              </div>
              <div className="userid justify-content-between d-flex align-items-center  w-100 mb-3">
                <p style={{ marginBottom: "0px", marginRight: "30px" }}>
                  {" "}
                  Permissions-{" "}
                </p>
                <p style={{ marginBottom: "0px" }}>
                  {permssionUserByModules &&
                    permssionUserByModules
                      .map((item) => item.module_name)
                      .join(", ")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Include</TableCell>
                  <TableCell>Exclude</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="w-auto">
                    {props.includePermissionModules &&
                      organizePermissions(props.includePermissionModules).map((parent) => {
                        if (parent.module_id == 1 || parent.module_id == 2) {
                          return null
                        }
                        return (
                          <div key={parent.module_id}>
                            <Checkbox
                              onChange={(e) => handleParentCheck(e, parent, 'included')}
                              checked={includedPermissions.includes(parent.module_id)}
                              value={parent.module_id}
                              name={parent.module_name}
                            >
                              {parent.module_name}
                            </Checkbox>
                            {parent.children &&
                              parent.children.map((child) => (
                                <div
                                  key={child.module_id}
                                  style={{ marginLeft: "20px" }}
                                >
                                  <Checkbox
                                    onChange={(e) => handleCheckUncheck(e, 'included')}
                                    checked={includedPermissions.includes(child.module_id)}
                                    value={child.module_id}
                                    name={child.module_name}
                                  >
                                    {child.module_name}
                                  </Checkbox>
                                </div>
                              ))}
                          </div>
                        )
                      }
                      )}
                  </TableCell>
                  <TableCell className="w-auto">
                    {props.excludePermissionModules &&
                      organizePermissions(props.excludePermissionModules).map((parent) => {
                        if (parent.module_id == 1 || parent.module_id == 2) {
                          return null
                        }
                        return (
                          <div key={parent.module_id}>
                            <Checkbox
                              onChange={(e) => handleParentCheck(e, parent, 'excluded')}
                              checked={excludePermissions.includes(parent.module_id)}
                              value={parent.module_id}
                              name={parent.module_name}
                            >
                              {parent.module_name}
                            </Checkbox>
                            {parent.children &&
                              parent.children.map((child) => (
                                <div
                                  key={child.module_id}
                                  style={{ marginLeft: "20px" }}
                                >
                                  <Checkbox
                                    onChange={(e) => handleCheckUncheck(e, 'excluded')}
                                    checked={excludePermissions.includes(child.module_id)}
                                    value={child.module_id}
                                    name={child.module_name}
                                  >
                                    {child.module_name}
                                  </Checkbox>
                                </div>
                              ))}
                            <br />
                          </div>
                        )
                      }
                      )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <button
            className="btn btn-primary m-auto d-block"
            type="submit"
          // 
          >
            Submit
          </button>
        </div>
      </form>

    </div>
  );
};

export default UserRoleManagementChange;