import React, { useState, useEffect } from "react";
import {
    fetchUserSettings,
    updateUserSetting,
    clearUserSettingRow
} from "../../../redux/slices/telegramSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    Table,
    TableCell,
    TableRow,
    TextField,
    tableCellClasses,
    Button,
    TableBody,
    Paper,
    Stack,
    Typography,
    Toolbar,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

const UpdateUserSetting = (props) => {
    const dispatch = useDispatch();

    const { userSettingRow } = useSelector(
        (state) => state.telegram
    );

    const [userSetting, setUserSetting] = useState({
        uid: "",
        amz_aff: "",
        fk_aff: "",
    });

    const changeHandler = (e) => {
        setUserSetting({ ...userSetting, [e.target.name]: e.target.value });
    };

    const resetForm = () => {
        setUserSetting({
            uid: "",
            amz_aff: "",
            fk_aff: "",
        });
    };

    const submitForm = (e) => {
        e.preventDefault();
        let data = {};
        data.uid = userSetting.uid;
        data.amz_aff = userSetting.amz_aff;
        data.fk_aff = userSetting.fk_aff;
        dispatch(updateUserSetting(data))
            .then(() => dispatch(fetchUserSettings()))
            .then(() => resetForm())
            .then(() => props.changeTab("showUserSetting"))
            .catch((err) => console.log(err, "err"));
    };

    const goBack = () => {
        props.changeTab("showUserSetting")
    }

    useEffect(() => {
        if (userSettingRow) {
            setUserSetting((prevData) => ({
                ...prevData,
                uid: userSettingRow.uid || "",
                amz_aff: userSettingRow.amz_aff || "",
                fk_aff: userSettingRow.fk_aff || "",
            }));
        }
        return () => {
            dispatch(clearUserSettingRow())
        }
    }, []);

    return (
        <>
            <IconButton onClick={goBack}>
                <ArrowBackIcon />
            </IconButton>
            <Paper elevation={3}>
                <form onSubmit={submitForm}>
                    <Stack spacing={3} direction="column">
                        <Toolbar
                            mt={3}
                            sx={{
                                padding: "1.2rem",
                                borderBottom: "2px solid var(--primary-color)",
                            }}
                        >
                            <Typography
                                sx={{
                                    color: "var(--primary-color)",
                                    fontWeight: "bold",
                                }}
                                variant="h5"
                                id="tableTitle"
                            >
                                Update User Setting
                            </Typography>
                        </Toolbar>
                        <Table
                            size="small"
                            sx={{
                                width: "auto",
                                margin: "8px !important",
                                marginTop: "16px !important",
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none",
                                },
                            }}
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>User ID:</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            placeholder="Enter User ID"
                                            name="userId"
                                            fullWidth
                                            required
                                            value={userSetting.uid}
                                            onChange={changeHandler}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Amazon Affiliate</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            placeholder="Enter Amazon Affiliate"
                                            name="from_channel"
                                            fullWidth
                                            required
                                            value={userSetting.amz_aff}
                                            onChange={changeHandler}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">
                                            <b>Flipkart Affiliate</b>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size="small"
                                            placeholder="Enter Flipkart Affiliate"
                                            name="to_channel"
                                            fullWidth
                                            required
                                            value={userSetting.fk_aff}
                                            onChange={changeHandler}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            size="large"
                                            className="d-block mx-auto mt-4 mb-3"
                                            onClick={submitForm}
                                        >
                                            <strong>Submit</strong>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Stack>
                </form>
            </Paper>
        </>
    );

}

export default UpdateUserSetting
