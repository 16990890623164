import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography } from 'antd';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { gotAdditionalStoreRow, showAdditionalStoreData } from "../../../../redux/slices/manageStoreSlice";


const ShowAdditionalStoreData = (props) => {
    const [storesData, setStoresData] = useState([]);
    // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { additionalStoreData } = useSelector(s => s.manageStores);
    const confirm = (e, value) => {
        let data = {};
        data.store_id = value[1];
        data.store_name = value[2];
        data.tips = JSON.parse(value[3]);
        data.tracking_speed = value[4];
        data.missing_cashback = value[5];
        data.expected_approval_date = value[6];
        dispatch(gotAdditionalStoreRow(data));
        props.setStoreType("additionalStoreData");
    }
    const cancel = () => {
        //console.log("Cancelled");
    }

    useEffect(() => {
        dispatch(showAdditionalStoreData());
        var stores_data = JSON.parse(localStorage.getItem("stores"))
        if (stores_data) {
            setStoresData(stores_data);
        }

    }, [])

    const columns = [

        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => confirm(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "store_id",
            label: "Store Id",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "tips",
            label: "Tips",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div><ul>{JSON.parse(value).map((item, index) => (<li>{item.cb_rate}</li>))}</ul></div>

            }
        },
        {
            name: "tracking_speed",
            label: "Tracking Speed",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "missing_cashback",
            label: "Missing Cashback",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "expected_approval_date",
            label: "expected approval date",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "created",
            label: "Created",
            dataIndex: new Date().toLocaleDateString(),
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    const options = {
        filterType: 'store_name',

    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Description (search by store_name)"}
                data={additionalStoreData && additionalStoreData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
}
export default ShowAdditionalStoreData;