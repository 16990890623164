// import React from "react";
// import { Provider } from "react-redux";
// import stores from "./stores";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import routes from "./routes/index";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import AllRoute from "./routes/index";

// const App = () => {
//   return (
//     <Provider store={stores}>
//       <BrowserRouter>
//         <Routes>
//           <Route element={<AllRoute/>} />
//         </Routes>
//       </BrowserRouter>
//     </Provider>
//   );
// };

// export default App;

import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AllRoute from "./routes/index";
import { AuthProvider, getUser } from "./context/authContext";
import Loader from "react-js-loader";
import axios from "axios";
import AppConstants from "./constants/appConstants";

const NotAuthorized = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>403 - Not Authorized</h1>
      <p>You do not have permission to access this page.</p>
      <p>Please contact your administrator.</p>
      <a href="https://team.hyyzo.com">Team Hyyzo Admin Panel</a>
    </div>
  );
};

const App = React.memo(() => {
  const [auth, setAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isAuthorized, setAuthorized] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authData = await getUser();
        if (authData) {
          const authorized = await checkAuthorization(authData);
          setAuthorized(authorized);
        } else {
          setAuthorized(false);
        }
        setAuth(authData);
      } catch (error) {
        setAuthorized(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  async function checkAuthorization(myAuth) {
    try {
      if (myAuth.status == "SIGNED_OUT") {
        return true;
      }
      if ((myAuth.user.role == 'admin' && (location.pathname == "/admin/modules" || location.pathname == "/admin/role-modules" || location.pathname == "/admin/approve")) || location.pathname == "/login") {
        return true
      }
      else {
        const resp = await axios({
          method: "post",
          url: `${AppConstants.baseURL}/auth/checkRoutePermission`,
          data: {
            role: myAuth.user.role,
            userId: myAuth.user.user_id,
          },
          withCredentials: true,
        });
        return resp.data.some(
          (route) => route.module_url === location.pathname
        );
      }
    } catch (error) {
      return false;
    }
  }

  if (isLoading) {
    return (
      <div className="row">
        <div className="item">
          <Loader
            type="spinner-circle"
            bgColor={"#000"}
            title={"Loading"}
            color={"#000"}
            size={100}
          />
        </div>
      </div>
    );
  }

  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  if (isAuthorized === false) {
    return <NotAuthorized />;
  }

  if (isAuthorized === true) {
    return (
      <AuthProvider myAuth={auth}>
        <AllRoute />
      </AuthProvider>
    );
  }

  return null;
});

export default App;
