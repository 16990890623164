import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import AppConstants from "../../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  activatedStoresData,
  gotStoresDataRow,
  showStore,
} from "../../../../redux/slices/manageStoreSlice";

const ShowStore = (props) => {
  const dispatch = useDispatch();
  const { allStores } = useSelector((s) => s.manageStores);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // let history = useHistory();  //useHistory is being deprecated in react-router v6 or above
  const navigate = useNavigate();
  // console.log(
  //   page,
  //   "page",
  //   rowsPerPage,
  //   "rowsPerPage",
  //   totalCount,
  //   "totalCount",
  //   isLoading,
  //   "isLoading"
  // );

  useEffect(() => {
    fetchStoreData(page, rowsPerPage);
    // dispatch(showStore());
  }, [page, rowsPerPage]);
  const fetchStoreData = (currentPage, limit) => {
    setIsLoading(true);
    const queryParams = {
      page: currentPage,
      limit: limit,
    };
    dispatch(showStore(queryParams))
      .then((response) => {
        // Assuming your API returns total count in the response
        if (response?.payload?.total) {
          setTotalCount(response.payload.total);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching stores:", error);
        setIsLoading(false);
      });
  };

  const edit = (e, value) => {
    let data = {};
    data.store_id = value[2];
    data.store_name = value[3];
    data.store_url = value[8];
    data.image = value[4];
    data.img_id = value[5];
    data.partner_id = value[6];
    data.active_partner = value[7];
    data.cashback_upto = value[9];
    data.store_page_url = value[10];
    data.site_table_content = value[11];
    dispatch(gotStoresDataRow(data));
    navigate("/managestores/createstore");
    props.setMainPage("createstore");
  };
  const cancel = () => {
    //console.log("Cancelled");
  };

  const callbackFunc = () => {
    dispatch(showStore());
  };

  const activated = (e, value) => {
    let data = {};
    data.store_id = value[2];
    data.type = "activate";
    let query = queryString.stringify(data);
    dispatch(activatedStoresData(query)).then(() => callbackFunc());
    message.success("Store data Activated Successfully");
  };

  const deactivated = (e, value) => {
    let data = {};
    data.store_id = value[2];
    data.type = "deactivate";
    let query = queryString.stringify(data);
    dispatch(activatedStoresData(query)).then(() => callbackFunc());
    message.success("Store data Deactivated Successfully");
  };

  const columns = [
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const isActive = tableMeta.rowData[12]
          return (
            <div style={{ display: "flex" }}>
              <Popconfirm
                title="activate the activity"
                onConfirm={(e) => activated(e, tableMeta.rowData)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
                disabled={isActive === 1}
              >
                <a
                  href="#"
                  style={{
                    padding: 5,
                    fontWeight: "bolder",
                    color: "#078101",
                    border: "1px solid #aaa",
                    borderRadius: "5px",
                    boxShadow: "2px 2px 0px 0px",
                    cursor: "pointer",
                    cursor: isActive === 1 ? "not-allowed" : "pointer",
                    pointerEvents: isActive === 1 ? "none" : "auto",
                    color: isActive === 1 ? "#aaa" : "#078101",
                  }}
                >
                  Activate
                </a>
              </Popconfirm>
              <Divider type="vertical"></Divider>
              <Popconfirm
                title="Deactivated"
                onConfirm={(e) => deactivated(e, tableMeta.rowData)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
                disabled={isActive === 0}
              >
                <a
                  href="#"
                  style={{
                    padding: 5,
                    fontWeight: "bolder",
                    color: "#FF1502",
                    border: "1px solid #aaa",
                    borderRadius: "5px",
                    boxShadow: "2px 2px 0px 0px",
                    cursor: "pointer",
                    cursor: isActive === 0 ? "not-allowed" : "pointer",
                    pointerEvents: isActive === 0 ? "none" : "auto",
                    color: isActive === 0 ? "#aaa" : "#FF1502",
                  }}
                >
                  Deactivate
                </a>
              </Popconfirm>
            </div>
          )
        },
      },
    },
    {
      name: "opertaion",
      label: "Operation",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Popconfirm
              title="Edit this task?"
              onConfirm={(e) => edit(e, tableMeta.rowData)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Edit</a>
            </Popconfirm>
          </div>
        ),
      },
    },
    {
      name: "store_id",
      label: "Store ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "store_name",
      label: "Store Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "image_url",
      label: "Image",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <img
            src={`${AppConstants.imageURL}${value}`}
            alt="image"
            style={{ width: "100px" }}
          />
        ),
      },
    },
    {
      name: "image",
      label: "ImageId",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "aff_links_id",
      label: "Aff links Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "partner",
      label: "Active Partner",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "url",
      label: "Store URL",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "cashback_upto",
      label: "cashback upto",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "store_page_url",
      label: "Store Page Url",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "site_table_content",
      label: "Site_table_content",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //     name: "id",
    //     label: "id",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     }
    // },
    // {
    //     name: "domain_name",
    //     label: "domain_name",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     }
    // }, {
    //     name: "subdomain_id",
    //     label: "subdomain_id",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     }
    // }, {
    //     name: "subdomain",
    //     label: "subdomain",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     }
    // },
    // {
    //     name: "match_pattern",
    //     label: "match_pattern",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     }
    // },
    // {
    //     name: "cart_page",
    //     label: "cart_page",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     }
    // },
    {
      name: "active",
      label: "Active",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "created",
      label: "Created",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  // const options = {
  //     filterType: 'store_name',
  // };
  const options = {
    filterType: "store_name",
    // serverSide: true,
    count: totalCount,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 25, 50],
    loading: isLoading,
    search: true,
    searchPlaceholder: "Search Store Name",
    searchOpen: false,
    onChangePage: (currentPage) => {
      setPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
      setPage(0);
    },
    // onSearchChange: (searchText) => {
    //   setPage(0);
    //   // Add search parameter to your API call
    //   const queryParams = {
    //     page: 0,
    //     limit: rowsPerPage,
    //     search: searchText,
    //   };
    //   dispatch(showStore(queryParams));
    // },
  };

  return (
    <Fragment>
      <MUIDataTable
        title={"Show Description  (search by store Name)"}
        data={allStores && allStores}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};
export default ShowStore;
