import React, { Component, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Layout, Menu, Breadcrumb, Dropdown, message } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  DashboardOutlined,
  CarryOutOutlined,
  FileAddOutlined,
  HistoryOutlined,
  FileImageOutlined,
  PictureOutlined,
  TeamOutlined,
  GlobalOutlined,
  AppstoreOutlined,
  MenuOutlined,
  DownOutlined,
  MailOutlined,
  FontSizeOutlined,
  BranchesOutlined,
  ContactsOutlined,
  DollarCircleOutlined,
  AntCloudOutlined,
  BellFilled,
} from "@ant-design/icons";
import { Badge } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import { Link, Navigate } from "react-router-dom";
import "../index.css";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  getStoreData,
  modulesByUserDataPanel,
} from "../redux/slices/layoutSlice";
// import { dispatch } from "rxjs/internal/observable/pairs";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/authSlice";
import { getProfile } from "../redux/slices/authSlice";
import { bottomNavigationActionClasses } from "@mui/material";
import { useAuth } from "../context/authContext";

// import { useNavigate } from "react-router-dom";
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const Logo = styled.div`
  text-align: center;
  margin: 16px 0;

  height: 30px;
  > p {
    margin: 0;
  }
  > p > b {
    color: #ff5733;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.3px;
    text-align: center;
  }
  > p > span {
    padding: 0 6px;
    font-size: 14px;
    color: #fff;
  }
`;

const HeaderComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // @media screen and (max-width: 480px) {
  // >div{
  //   margin-left: 15px !important;
  // }
  // }
`;

const UserComp = styled.div`
  display: flex;
  cursor: pointer;
  > p > b {
    color: #ff5733;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.3px;
    text-align: center;
    margin-bottom: 0px;
    margin-right: 10px;
  }
  > p > span {
    padding: 0 6px;
    font-size: 14px;
    color: #fff;
  }
  > p > b > span {
    padding-right: 10px;
    vertical-align: baseline;
  }
`;

const NotificationContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const userData = {
  role: "admin",
};

let theme = createTheme({});

const LayoutCover = (props) => {
  const { isAuthenticate, logout } = useAuth();
  const dispatch = useDispatch();
  // console.log(data, ">>>>> Data");
  const navigate = useNavigate();
  const [modules, setModules] = useState([]);
  const [layoutData, setlayoutData] = useState({
    collapsed: false,
  });
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    setlayoutData({ collapsed: !isSmUp });
  }, [isSmUp]);

  const data = useSelector((state) => state.layout.data.modulesByUserData);
  const user = useSelector((state) => state.auth.getProfile);
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  useEffect(() => {
    dispatch(modulesByUserDataPanel()).then((d) => {
      setModules(d.payload);
    });
    dispatch(getStoreData());
    dispatch(getProfile());
    setModules(data);
  }, []);

  const { children, storeData, paymentLength } = props;
  const onCollapse = (collapsed) => setlayoutData({ collapsed });

  const logoutHandler = async () => {
    await logout()
      .then(() => {
        message.success("Logged Out Successfully!");
        setTimeout(() => {
          window.location.reload(); //for reload of window screen to navigate to "/login" route automatically
        }, 100);
      })
      .catch((err) => {
        console.log(err);
      });
    // navigate('/login');
  };

  // const filterModuleSub_module = () => {
  //   if (props.modules_by_users) {
  //     let data = props.modules_by_users.filter(val => val.module_url !== null);
  //     let data1 = props.modules_by_users.filter(val => val.module_url == null);
  //     setlayoutData({ ...layoutData, moduleData: data, sub_moduleData: data1 })
  //   }

  // }

  const toggle = () => {
    setlayoutData({
      collapsed: !layoutData.collapsed, //this is a temporary change from the current state of collapsed: !collapsed to collapsed: !layoutData.collapsed
    });
  };

  const saveStoreData = () => {
    if (props.storeData)
      localStorage.setItem("stores", JSON.stringify(props.storeData));
  };

  let href = window.location.href.split("/");
  // console.log(props.modules_by_users, "moduleByusers");

  if (href.length > 4) {
    href = href[4];
  } else {
    href = href[3];
  }
  const dropdownMenu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile">
          <UserOutlined />
          <span>Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item onClick={logoutHandler}>
        <LogoutOutlined />
        <span>LOGOUT</span>
      </Menu.Item>
    </Menu>
  );

  const handleNotification = () => {
    // history.push("/taskreminder"); //useHistory is deprecated in react-router v6 and above
    navigate("/taskreminder");
  };

  const taskArr = [props.taskReminderData];

  // console.log([props.taskReminderData].length, "layout")
  return (
    <div>
      <Layout style={{ minHeight: "100vh", position: "relative" }}>
        {isAuthenticate && (
          <Sider
            collapsible
            collapsed={layoutData.collapsed}
            onCollapse={onCollapse}
            trigger={null}
          // style={{
          //   position: !isSmUp ? "absolute" : "relative",
          //   zIndex: !isSmUp ? 1000 : "auto",
          //   width: !isSmUp ? 80 : 200,
          // }}
          >
            <Logo>
              {!layoutData.collapsed && (
                <p>
                  <b>{user && user.name}</b> <span>{user && user.field}</span>
                </p>
              )}
            </Logo>

            <Menu
              theme="dark"
              defaultSelectedKeys={["/" + href]}
              selectedKeys={["/" + href]}
              mode="inline"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
            >
              {data &&
                data.length !== 0 &&
                data.map((item) => {
                  if (!item.sub_module) {
                    return (
                      <Menu.Item key={item.module_url}>
                        <Link to={item.module_url}>
                          <DashboardOutlined />
                          <span>{item.module_name}</span>
                        </Link>
                      </Menu.Item>
                    );
                  } else {
                    return (
                      <SubMenu
                        key={item.module_name}
                        title={
                          <span>
                            <CarryOutOutlined />
                            <span>{item.module_name}</span>
                          </span>
                        }
                      >
                        {item.sub_module.map((subItem) => (
                          <Menu.Item key={subItem.sub_module_url}>
                            <Link to={subItem.sub_module_url}>
                              <FileAddOutlined />
                              <span>{subItem.sub_module_name}</span>
                            </Link>
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    );
                  }
                })}
              <Menu.Item onClick={logoutHandler}>
                <LogoutOutlined />
                <span>LOGOUT</span>
              </Menu.Item>
            </Menu>
          </Sider>
        )}
        <Layout>
          <Header
            style={{ padding: 0, height: "" }}
            className="site-layout-sub-header-background"
          >
            <HeaderComp>
              <div
                className="hamburger"
                style={{ marginTop: "5px", zIndex: 999 }}
              >
                {layoutData.collapsed ? (
                  <MenuOutlined
                    style={{ color: "#fff", fontSize: "24px" }}
                    onClick={toggle}
                  />
                ) : (
                  <MenuOutlined
                    style={{ color: "#fff", fontSize: "24px" }}
                    onClick={toggle}
                  />
                )}
              </div>

              <div
                style={{
                  position: "absolute",
                  right: "152px",
                  top: "2px",
                }}
              >
                <Badge
                  count={taskArr[0] === undefined ? 0 : taskArr.length}
                  size="small"
                >
                  <BellFilled
                    onClick={handleNotification}
                    style={{
                      color: "#fff",
                      fontSize: "24px",
                    }}
                  />
                </Badge>
              </div>
              <UserComp>
                <p onClick={logoutHandler}>
                  <b>
                    <LogoutOutlined />
                    LOGOUT
                  </b>
                </p>
              </UserComp>
            </HeaderComp>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, background: "#fff", minHeight: 360 }}
            >
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>Hyyzo Admin Panel</Footer>
        </Layout>
      </Layout>
    </div>
  );
};
// class LayoutCover extends Component {
//   state = {
//     collapsed: false,
//     menuData: [],
//   };

//   onCollapse = (collapsed) => this.setState({ collapsed });

//   logoutHandler = () => {
//     this.props.logout();
//     return <Redirect to="/login" from={this.props.location} />;
//   };

//   getModuleName = (moduleId, modulesData) => {
//     for (var i = 0; i < modulesData.length; i++) {
//       if (modulesData[i].module_id == moduleId) {
//         return modulesData[i].module_name;
//       }
//     }
//   };

//   componentDidMount = () => {
//     // this.props.getProfile(this.cbFunc)
//     this.props.getPaymentStatus();
//     this.props.getStoreData(this.saveStoreData);
//     this.props.modulesByUserData();
//     // if (this.props.storeData) {
//     //   localStorage.setItem("stores", JSON.stringify(this.props.storeData))
//     // }
//   }

//   toggle = () => {
//     this.setState({
//       collapsed: !this.state.collapsed,
//     });
//   };

//   saveStoreData = () => {
//     if (this.props.storeData) localStorage.setItem("stores", JSON.stringify(this.props.storeData))
//   };

//   //  filterMenuData = () => {
//   //     if (this.props.modules_by_users) {
//   //       let data = this.props.modules_by_users.map((item,index)=>{
//   //         console.log(item.module_id)
//   //       })
//   //       this.setState({ menuData: [data] });
//   //     }
//   //   }

//   render() {
//     const { children, user, storeData, paymentLength } = this.props;
//     const { collapsed } = this.state;
//     let href = window.location.href.split("/");
//     console.log(this.props.modules_by_users, "hkwfhsdk")

//     // console.log(this.props.paymentStatusData && this.props.paymentStatusData.length,"paymentlength..........")
//     //console.log("location", storeData);
//     if (href.length > 4) {
//       href = href[4];
//     } else {
//       href = href[3];
//     }
//     const dropdownMenu = (
//       <Menu>
//         <Menu.Item>
//           <Link to="/profile">
//             <UserOutlined />
//             <span>Profile</span>
//           </Link>
//         </Menu.Item>
//         <Menu.Item onClick={this.logoutHandler}>
//           <LogoutOutlined />
//           <span>LOGOUT</span>
//         </Menu.Item>
//       </Menu>
//     );
//     return (
//       <div>
//         {userData && userData.role == "admin" ? (
//           <Layout style={{ minHeight: "100vh" }}>
//             <Sider
//               collapsible
//               collapsed={collapsed}
//               onCollapse={this.onCollapse}
//               trigger={null}
//             >
//               <Logo>
//                 {!collapsed && (
//                   <p>
//                     <b>{user && user.name}</b> <span>{user && user.field}</span>
//                   </p>
//                 )}
//               </Logo>
//               {/* {this.props.modules_by_users && this.props.modules_by_users} */}
//               <Menu
//                 theme="dark"
//                 defaultSelectedKeys={["/" + href]}
//                 selectedKeys={["/" + href]}
//                 mode="inline"
//               >
//                 <Menu.Item key="/">
//                   <Link to="/">
//                     <DashboardOutlined />
//                     <span>Dashboard</span>
//                   </Link>
//                 </Menu.Item>
//                 <Menu.Item key="/profile">
//                   <Link to="/profile">
//                     <UserOutlined />
//                     <span>Profile</span>
//                   </Link>
//                 </Menu.Item>
//                 <SubMenu
//                   key="sub1"
//                   title={
//                     <span>
//                       <CarryOutOutlined />
//                       <span>Task</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/addnewactivity">
//                     <Link to="/activities/addnewactivity">
//                       <FileAddOutlined />
//                       <span>Add New Task</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/showactivities">
//                     <Link to="/activities/showactivities">
//                       <HistoryOutlined />
//                       <span>Show Task</span>
//                     </Link>
//                   </Menu.Item>
//                 </SubMenu>
//                 <SubMenu
//                   key="sub2"
//                   title={
//                     <span>
//                       <FileImageOutlined />
//                       <span>Gallery</span>
//                     </span>
//                   }
//                 >
//                   {/* <Menu.Item key="/a">
//                     <Link to="/gallery/a">
//                       <FileAddOutlined />
//                       <span>Add Images</span>
//                     </Link>
//                   </Menu.Item> */}
//                   {/* <Menu.Item key="/addimages">
//                     <Link to="/gallery/addimages">
//                       <Icon type="file-add" />
//                       <span>Add Images</span>
//                     </Link>
//                   </Menu.Item> */}
//                   <Menu.Item key="/showimages">
//                     <Link to="/gallery/showimages">
//                       <HistoryOutlined />
//                       <span>Add/Show Images</span>
//                     </Link>
//                   </Menu.Item>
//                 </SubMenu>
//                 {/* <SubMenu
//               key="sub3"
//               title={
//                 <span>
//                   <Icon type="picture" />
//                   <span>Manage Banners/Offers</span>
//                 </span>
//               }
//             >
//               <Menu.Item key="/uploadimage">
//                 <Link to="/managebanners/uploadimage">
//                   <Icon type="file-add" />
//                   <span>Upload Image</span>
//                 </Link>
//               </Menu.Item>
//               <Menu.Item key="/liveimage">
//                 <Link to="/managebanners/livebanners">
//                   <Icon type="history" />
//                   <span>Live Banners</span>
//                 </Link>
//               </Menu.Item>
//               <Menu.Item key="/liveoffers">
//                 <Link to="/managebanners/liveoffers">
//                   <Icon type="history" />
//                   <span>Live Offers</span>
//                 </Link>
//               </Menu.Item>
//             </SubMenu>
//             <SubMenu
//               key="sub4"
//               title={
//                 <span>
//                   <Icon type="team" />
//                   <span>Testimonials</span>
//                 </span>
//               }
//             >
//               <Menu.Item key="/addtestimonials">
//                 <Link to="/testimonials/addtestimonials">
//                   <Icon type="file-add" />
//                   <span>Add Testimonials</span>
//                 </Link>
//               </Menu.Item>
//               <Menu.Item key="/showtestimonials">
//                 <Link to="/testimonials/showtestimonials">
//                   <Icon type="history" />
//                   <span>Show Testimonials</span>
//                 </Link>
//               </Menu.Item>
//             </SubMenu>
//             <SubMenu
//               key="sub5"
//               title={
//                 <span>
//                   <Icon type="global" />
//                   <span>News</span>
//                 </span>
//               }
//             >
//               <Menu.Item key="/addnews">
//                 <Link to="/news/addnews">
//                   <Icon type="file-add" />
//                   <span>Add News</span>
//                 </Link>
//               </Menu.Item>
//               <Menu.Item key="/shownews">
//                 <Link to="/news/shownews">
//                   <Icon type="history" />
//                   <span>Show News</span>
//                 </Link>
//               </Menu.Item>
//             </SubMenu>
//             <SubMenu
//               key="sub6"
//               title={
//                 <span>
//                   <Icon type="appstore" />
//                   <span>Manage Stores</span>
//                 </span>
//               }
//             >
//               <Menu.Item key="/addcategory">
//                 <Link to="/managestores/addcategory">
//                   <Icon type="file-add" />
//                   <span>Add Category</span>
//                 </Link>
//               </Menu.Item>
//               <Menu.Item key="/adddescription">
//                 <Link to="/managestores/adddescription">
//                   <Icon type="file-add" />
//                   <span>Add Description</span>
//                 </Link>
//               </Menu.Item>
//               <Menu.Item key="/addimage_link">
//                 <Link to="/managestores/addimage_link">
//                   <Icon type="file-add" />
//                   <span>AddImage_Link</span>
//                 </Link>
//               </Menu.Item>
//               </SubMenu> */}
//                 <SubMenu
//                   key="sub3"
//                   title={
//                     <span>
//                       <PictureOutlined />
//                       <span>Manage Banners/Offers</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/uploadimage">
//                     <Link to="/managebanners/uploadimage">
//                       <FileAddOutlined />
//                       <span>Upload Image</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/liveimage">
//                     <Link to="/managebanners/livebanners">
//                       <HistoryOutlined />
//                       <span>Live Banners</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/liveoffers">
//                     <Link to="/managebanners/liveoffers">
//                       <HistoryOutlined />
//                       <span>Live Offers</span>
//                     </Link>
//                   </Menu.Item>
//                 </SubMenu>
//                 <SubMenu
//                   key="sub4"
//                   title={
//                     <span>
//                       <TeamOutlined />
//                       <span>Testimonials</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/addtestimonials">
//                     <Link to="/testimonials/addtestimonials">
//                       <FileAddOutlined />
//                       <span>Add Testimonials</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/showtestimonials">
//                     <Link to="/testimonials/showtestimonials">
//                       <HistoryOutlined />
//                       <span>Show Testimonials</span>
//                     </Link>
//                   </Menu.Item>
//                 </SubMenu>
//                 <SubMenu
//                   key="sub5"
//                   title={
//                     <span>
//                       <GlobalOutlined />
//                       <span>News</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/addnews">
//                     <Link to="/news/addnews">
//                       <FileAddOutlined />
//                       <span>Add News</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/shownews">
//                     <Link to="/news/shownews">
//                       <HistoryOutlined />
//                       <span>Show News</span>
//                     </Link>
//                   </Menu.Item>
//                 </SubMenu>
//                 <SubMenu
//                   key="sub6"
//                   title={
//                     <span>
//                       <AppstoreOutlined />
//                       <span>Manage Stores</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/createstore">
//                     <Link to="/managestores/createstore">
//                       <FileAddOutlined />
//                       <span>Create/show Store</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/storedescription">
//                     <Link to="/managestores/storedescription">
//                       <HistoryOutlined />
//                       <span>Store Description</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/category">
//                     <Link to="/managestores/category">
//                       <HistoryOutlined />
//                       <span>Category</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/additionalstoredata">
//                     <Link to="/managestores/additionalstoredata">
//                       <FileAddOutlined />
//                       <span>Additional Store Data</span>
//                     </Link>
//                   </Menu.Item>

//                   <Menu.Item key="/deeplink">
//                     <Link to="/managestores/deeplink">
//                       <FileAddOutlined />
//                       <span>Deeplink Data</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/storeschema">
//                     <Link to="/managestores/storeschema">
//                       <FileAddOutlined />
//                       <span>Store Schema</span>
//                     </Link>
//                   </Menu.Item>
//                   {/* <Menu.Item key="/addimage_link">
//                     <Link to="/managestores/addimage_link">
//                       <FileAddOutlined />
//                       <span>AddImage_Link</span>
//                     </Link>
//                   </Menu.Item> */}

//                   {/* <Menu.Item key="/showdescription">
//                     <Link to="/managestores/showdescription">
//                       <HistoryOutlined />
//                       <span>Show Description</span>
//                     </Link>
//                   </Menu.Item> */}
//                 </SubMenu>
//                 <SubMenu
//                   key="sub7"
//                   title={
//                     <span>
//                       <FontSizeOutlined />
//                       <span>Header/Footer</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/addheaderfooter">
//                     <Link to="/headerfooter/addheaderfooter">
//                       <HistoryOutlined />
//                       <span>Add/Show Header Footer</span>
//                     </Link>
//                   </Menu.Item>
//                   {/* <Menu.Item key="/showheaderfooter">
//                     <Link to="/headerfooter/showheaderfooter">
//                     <HistoryOutlined />
//                       <span>Show Header/Footer</span>
//                     </Link>
//                   </Menu.Item> */}
//                 </SubMenu>

//                 <Menu.Item key="/contactedusers">
//                   <Link to="/contactedusers">
//                     <ContactsOutlined />
//                     <span>Contacted User</span>
//                   </Link>
//                 </Menu.Item>
//                 <SubMenu
//                   key="sub9"
//                   title={
//                     <span>
//                       <BranchesOutlined />
//                       <span>Affiliate</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/afflinking">
//                     <Link to="/affiliate/afflinking">
//                       <HistoryOutlined />
//                       <span>Affiliate Linking</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/masterpartners">
//                     <Link to="/affiliate/masterpartners">
//                       <BranchesOutlined />
//                       <span>Master Partners</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/storepartners">
//                     <Link to="/affiliate/storepartners">
//                       <BranchesOutlined />
//                       <span>Store Partners</span>
//                     </Link>
//                   </Menu.Item>
//                 </SubMenu>
//                 <SubMenu
//                   key="sub10"
//                   title={
//                     <span>
//                       <FileImageOutlined />
//                       <span>Pointer Poster</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/responsemaster">
//                     <Link to="/points/responsemaster">
//                       <HistoryOutlined />
//                       <span>Add/Show Response Master</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/pointmaster">
//                     <Link to="/points/pointmaster">
//                       <HistoryOutlined />
//                       <span>Add/Show Point Master</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/uploadcsv">
//                     <Link to="/points/uploadcsv">
//                       <HistoryOutlined />
//                       <span>upload CSV</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/approvalmaster">
//                     <Link to="/points/approvalmaster">
//                       <HistoryOutlined />
//                       <span>Add/Show Approval Master</span>
//                     </Link>
//                   </Menu.Item>
//                   {/* <Menu.Item key="/showpoits">
//                     <Link to="/points/showpoints">
//                       <FileImageOutlined />
//                       <span>Add Partners</span>
//                     </Link>
//                   </Menu.Item> */}
//                 </SubMenu>

//                 {/* Payment status Layout */}

//                 <SubMenu
//                   key="sub11"
//                   title={
//                     <span>
//                       <DollarCircleOutlined />
//                       <span>Payment  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
//                         {this.props.paymentStatusData && this.props.paymentStatusData.length ? this.props.paymentStatusData.length : 0}
//                         <span class="visually-hidden"> status</span>
//                       </span></span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/paymentstatus">
//                     <Link to="/redeem/paymentstatus">
//                       <DollarCircleOutlined />
//                       <span>Payment Status</span>
//                     </Link>
//                   </Menu.Item>
//                 </SubMenu>

//                 {/* Redemption start */}
//                 <SubMenu
//                   key="sub12"
//                   title={
//                     <span>
//                       <DollarCircleOutlined />
//                       <span>Redemption</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/addRedeem">
//                     <Link to="/redemption/addredeem">
//                       <HistoryOutlined />
//                       <span>Add/Show Redeem</span>
//                     </Link>
//                   </Menu.Item>
//                   {/* <Menu.Item key="/showRedeem">
//                     <Link to="/redemption/showredeem">
//                       <AccountBookOutlined />
//                       <span>Show Redeem</span>
//                     </Link>
//                   </Menu.Item> */}
//                 </SubMenu>
//                 {/* Redemption end */}

//                 {/* Email start */}
//                 <SubMenu
//                   key="sub13"
//                   title={
//                     <span>
//                       <MailOutlined />
//                       <span>Email</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/addemail">
//                     <Link to="/email/addemail">
//                       <HistoryOutlined />
//                       <span>Add/Show Email</span>
//                     </Link>
//                     {/* </Menu.Item>
//                   <Menu.Item key="/showemail">
//                     <Link to="/email/showemail">
//                       <MailOutlined />
//                       <span>Show Email</span>
//                     </Link>*/}
//                   </Menu.Item>
//                 </SubMenu>
//                 {/* Email end */}

//                 {/* Crm Module start */}
//                 <SubMenu
//                   key="sub14"
//                   title={
//                     <span>
//                       <AntCloudOutlined />
//                       <span>CRM-(Customer Relationship Model)</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/redeemreq">
//                     <Link to="/crm/redeemreq">
//                       <HistoryOutlined />
//                       <span>Cashback Claims</span>
//                     </Link>
//                     {/* </Menu.Item>
//                   <Menu.Item key="/showemail">
//                     <Link to="/email/showemail">
//                       <MailOutlined />
//                       <span>Show Email</span>
//                     </Link>*/}
//                   </Menu.Item>
//                   <Menu.Item key="/searchbyclickid">
//                     <Link to="/crm/searchbyclickid">
//                       <HistoryOutlined />
//                       <span>Click Id operation</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/allordersdata">
//                     <Link to="/crm/allordersdata">
//                       <HistoryOutlined />
//                       <span>All Orders Details</span>
//                     </Link>
//                   </Menu.Item>
//                   <Menu.Item key="/top10data">
//                     <Link to="/crm/top10data">
//                       <HistoryOutlined />
//                       <span>Top 10 Data</span>
//                     </Link>
//                   </Menu.Item>
//                 </SubMenu>

//                 {/* Crm Module start */}
//                 <SubMenu
//                   key="sub15"
//                   title={
//                     <span>
//                       <AntCloudOutlined />
//                       <span>User Details</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/newusers">
//                     <Link to="/user/newusers">
//                       <HistoryOutlined />
//                       <span>New SignUp user</span>
//                     </Link>
//                   </Menu.Item>
//                 </SubMenu>

//                 <SubMenu
//                   key="sub16"
//                   title={
//                     <span>
//                       <AntCloudOutlined />
//                       <span>Manual Bonus Points</span>
//                     </span>
//                   }
//                 >
//                   <Menu.Item key="/bonuspoints">
//                     <Link to="/bonus/bonuspoints">
//                       <HistoryOutlined />
//                       <span>Bonus Point Post </span>
//                     </Link>
//                   </Menu.Item>
//                 </SubMenu>

//                 {/* Email end */}

//                 <Menu.Item onClick={this.logoutHandler}>
//                   <LogoutOutlined />
//                   <span>LOGOUT</span>
//                 </Menu.Item>
//               </Menu>
//             </Sider>
//             <Layout>
//               <Header
//                 style={{ padding: 0, height: "" }}
//                 className="site-layout-sub-header-background"
//               >
//                 <HeaderComp>
//                   <div className="hamburger" style={{ marginTop: "5px" }}>
//                     {this.state.collapsed ? (
//                       <MenuOutlined style={{ color: "#fff", fontSize: "24px" }}
//                         onClick={this.toggle} />
//                     ) : (
//                       <MenuOutlined style={{ color: "#fff", fontSize: "24px" }}
//                         onClick={this.toggle} />
//                     )}
//                   </div>

//                   <UserComp>
//                     {/* <NotificationContainer>
//           <Icon type="bell" style={{color:"#fff",fontSize:"30px",textAlign:"end"}} />
//           <NotificationBadge count={2} effect={Effect.SCALE} />
//           </NotificationContainer> */}
//                     <p onClick={this.logoutHandler}>
//                       <b><LogoutOutlined />
//                         LOGOUT</b>
//                     </p>
//                     {/* <p>
//                       <b>{user && user.name}</b>
//                     </p> */}
//                     {/* <Dropdown
//                       overlay={dropdownMenu}
//                       overlayStyle={{ marginRight: "20px" }}
//                     >
//                       <a
//                         className="ant-dropdown-link"
//                         onClick={(e) => e.preventDefault()}
//                       >
//                         <DownOutlined style={{
//                           color: "#fff",
//                           fontSize: "20px",
//                           marginRight: "10px",
//                         }} />
//                       </a>
//                     </Dropdown> */}
//                   </UserComp>
//                 </HeaderComp>
//                 {/* <Header className="site-layout-background" style={{ padding: 0 }}>
//             {React.createElement(this.state.collapsed ? <Icon type="MenuUnfoldOutlined" /> : <Icon type="MenuFoldOutlined" />, {
//               className: 'trigger',
//               onClick: this.toggle,
//             })} */}
//               </Header>
//               <Content style={{ margin: "0 16px" }}>
//                 <div
//                   className="site-layout-background"
//                   style={{ padding: 24, background: "#fff", minHeight: 360 }}
//                 >
//                   {children}
//                 </div>
//               </Content>
//               <Footer style={{ textAlign: "center" }}>
//                 Hyyzo Admin Panel
//               </Footer>
//             </Layout>
//           </Layout>
//         ) : (
//           <UserLayout />
//         )}
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     storeData: state.stores.storeData,
//     modules_by_users: state.auth.modules_by_users,
//     taskReminderData: state.taskreminder.taskreminderData

//   };
// };
// const mapDispatchToProps = (dispatch) => ({
//   getStoreData: (cbFunc) => dispatch(getStoreData(cbFunc)),
//   // getPaymentStatus: (data) => dispatch(getPaymentStatus(data)),
//   modulesByUserData: () => dispatch(modulesByUserData()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(LayoutCover);

export default LayoutCover;
