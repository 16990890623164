import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "antd";
import UpdateUserSetting from "./UpdateUserSetting";
import ShowUserSetting from "./ShowUserSetting";
const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;

  >div{
      >div{
        >button{
            background: linear-gradient(259deg, rgb(236, 94, 58) 0%, rgb(249, 186, 99) 100%) 0% 0% no-repeat padding-box padding-box transparent;
            padding:7px 20px;
            border-radius:20px;
            float:right;
        }
      }
  }

`;
const BodyContainer = styled.div`
  overflow: auto;
`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

function UserSetting() {
  const [storeType, setStoreType] = useState("showUserSetting");
  const changeTab = (val) => {
    setStoreType(val);
  };
  return (
    <Wrapper>
      <BodyContainer>
        {storeType == "updateUserSetting" && (
          <UpdateUserSetting changeTab={changeTab} />
        )}
        {storeType == "showUserSetting" && <ShowUserSetting changeTab={changeTab} />}
      </BodyContainer>
    </Wrapper>
  );
}

export default UserSetting;
