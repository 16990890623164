import React, { useState } from "react";
import styled from "styled-components";
import { Typography } from "antd";
import AddAutoPostChannel from "./AddAutoPostChannel";
import ShowAutoPost from "./ShowAutoPostChannel";
const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;

  >div{
      >div{
        >button{
            background: linear-gradient(259deg, rgb(236, 94, 58) 0%, rgb(249, 186, 99) 100%) 0% 0% no-repeat padding-box padding-box transparent;
            padding:7px 20px;
            border-radius:20px;
            float:right;
        }
      }
  }

`;
const BodyContainer = styled.div`
  overflow: auto;
`;
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

function AutoPostFromChannel() {
  const [storeType, setStoreType] = useState("showAutoPost");
  const changeTab = (val) => {
    setStoreType(val);
  };
  return (
    <Wrapper>
      <Title className="my-3" level={3}>Create/show Auto-Post Channel </Title>
      <div className="row">
        <div className="col-12 col-lg-12">
          {storeType == "showAutoPost" && (
            <button onClick={() => changeTab("addAutoPost")}>Create Auto-Post Channel</button>
          )}
          {storeType == "addAutoPost" && (
            <button onClick={() => changeTab("showAutoPost")}>Show Auto-Post Channel</button>
          )}
        </div>
      </div>
      <BodyContainer>
        {storeType == "addAutoPost" && (
          <AddAutoPostChannel changeTab={changeTab} />
        )}
        {storeType == "showAutoPost" && <ShowAutoPost changeTab={changeTab} />}
      </BodyContainer>
    </Wrapper>
  );
}

export default AutoPostFromChannel;
