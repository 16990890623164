import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/authApi";
import { message } from "antd";

export const signIn = createAsyncThunk("auth/login", async (loginData) => {
  try {
    const response = await api.login(loginData);
    return response.data;
  } catch (err) {
    return console.log(err, "Error from auth slice");
  }
});

export const logout = createAsyncThunk("/auth/logout", async () => {
  try {
    const response = await api.logout();
    console.log(response);
  } catch (error) { }
});

export const signup = createAsyncThunk(
  "/auth/signup",
  async (signupData, { rejectWithValue }) => {
    try {
      const response = await api.signupApi(signupData);
      if (response.data.code === 200) {
        message.success("Signup successfully");
        return response.data;
      } else {
        message.error(
          response.data.message
            ? response.data.message
            : "Something went Wrong!"
        );
        return rejectWithValue(response.data);
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(response.data);
    }
  }
);

export const getProfile = createAsyncThunk(
  "/auth/profile",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getProfile();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showRoles = createAsyncThunk(
  "/auth/showroles",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showRolesApi();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "/auth/changepassword",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await api.changePassword(data);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message
            ? resp.data.message
            : "Password Changed Successfully!"
        );
        // return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went Wrong!"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showAllModules = createAsyncThunk(
  "auth/showallmodules",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showAllModules();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================add new or update module=================================//
export const addUpdateNewModules = createAsyncThunk(
  "auth/newmodules",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.addNewModules(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Action successful"
        );
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Something went wrong"
        );
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

//=================================activate/deactivate module=================================//
export const activateModulesData = createAsyncThunk(
  "auth/activemodules",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.activateModules(formData);
      if (resp.data.code === 200) {
        message.success("Action successful");
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "Action not taken"
        );

        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const addModulePermission = createAsyncThunk(
  "auth/addmodulepermission",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.modulePermissions(formData);
      if (resp.data.code === 200) {
        message.success(resp.data.message ? resp.data.message : "changed");
        return resp.data.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "somrthing went wrong"
        );

        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getAllPanelUsersData = createAsyncThunk(
  "auth/allpanelusers",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getAllPanelUsers();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getAllUsersData = createAsyncThunk(
  "auth/allusers",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.getAllUsers();
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getuserManagementRolesData = createAsyncThunk(
  "auth/usermangageRoles",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await api.userManagementRolesData(formData);
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const showRolesData = createAsyncThunk(
  "auth/showroles",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await api.showRolesApi();
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const updatePanelUserData = createAsyncThunk(
  "auth/updatepaneluserss",
  async ({ formData, cbFunc }, { rejectWithValue }) => {
    try {
      const resp = await api.updatePanelUser(formData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "Updated successfully"
        );
        cbFunc();
        return resp.data.data;
      } else {
        message.error(resp.data.message ? resp.data.message : "Not updated");
        return rejectWithValue(resp.data);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuthenticated: false,
    getProfile: [],
    show_roles: [],
    allModules: [],
    success: true,
    loading: true,
    userPermissionsData: {},
    allPanelUsers: [],
    userRolesData: null,
    modulesData: null,
    allUsers: []
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUserManagementRolesData: (state) => {
      state.userPermissionsData = {};
    }
  },

  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.isAuthenticated = true;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(signup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signup.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // =====================================================================================================================================

    builder.addCase(logout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      state.user = null;
      state.isAuthenticated = false;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // ===========================================================================================================

    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.getProfile = action.payload;
      state.isAuthenticated = true;
      // console.log(action.payload, "actionpayload");
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.isAuthenticated = false;
    });
    // ===========================================================================================================


    builder.addCase(showAllModules.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showAllModules.fulfilled, (state, action) => {
      state.allModules = action.payload;
      state.loading = false;
    });
    builder.addCase(showAllModules.rejected, (state, action) => {
      state.error = action.payload;
    });


    builder.addCase(addUpdateNewModules.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addUpdateNewModules.fulfilled, (state, action) => {
      state.success = action.payload;
      state.loading = false;
    });
    builder.addCase(addUpdateNewModules.rejected, (state, action) => {
      state.error = action.payload;
    });


    builder.addCase(activateModulesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activateModulesData.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(activateModulesData.rejected, (state, action) => {
      state.error = action.payload;
    });


    builder.addCase(getAllPanelUsersData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPanelUsersData.fulfilled, (state, action) => {
      state.allPanelUsers = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllPanelUsersData.rejected, (state, action) => {
      state.error = action.payload;
    });


    builder.addCase(getAllUsersData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllUsersData.fulfilled, (state, action) => {
      state.allUsers = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllUsersData.rejected, (state, action) => {
      state.error = action.payload;
    });


    builder.addCase(addModulePermission.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addModulePermission.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addModulePermission.rejected, (state, action) => {
      state.error = action.payload;
    });


    builder.addCase(getuserManagementRolesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getuserManagementRolesData.fulfilled, (state, action) => {
      state.userPermissionsData = {
        data: action.payload.data,
        userExcludedRole: action.payload.userExcludedRole,
        userIncludedRoles: action.payload.userIncludedRoles,
      };
    });
    builder.addCase(getuserManagementRolesData.rejected, (state, action) => {
      state.error = action.payload;
    });


    builder.addCase(showRolesData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showRolesData.fulfilled, (state, action) => {
      state.userRolesData = action.payload.data;
      state.modulesData = action.payload.modules_data;
    });
    builder.addCase(showRolesData.rejected, (state, action) => {
      state.error = action.payload;
    });


    builder.addCase(updatePanelUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePanelUserData.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(updatePanelUserData.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(showRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showRoles.fulfilled, (state, action) => {
      state.show_roles = action.payload.data;
      // console.log(action.payload, "actionpayload");
    });
    builder.addCase(showRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.isAuthenticated = false;
    });

    //=================================changePassword===========================//
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { setUser, clearUserManagementRolesData } = authSlice.actions;

export default authSlice.reducer;
