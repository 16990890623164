import React, { useState, useEffect } from "react";
import {
    createAutoPost,
    updateTelegramAutoPost,
    getAutoPostRow,
    clearAutoPostRow
} from "../../../redux/slices/telegramSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    Table,
    TableCell,
    TableRow,
    TextField,
    tableCellClasses,
    Button,
    TableBody,
    Paper,
    Stack,
    Typography,
    Toolbar,
    Autocomplete,
} from "@mui/material";
import { message } from "antd";

const AddAutoPostChannel = (props) => {
    const dispatch = useDispatch();

    const { autoPostRow } = useSelector(
        (state) => state.telegram
    );

    // const newData = useSelector(s=>s)
    // console.log(newData,"edit data")
    // console.log(storedata, "storedata");
    const [isUpdatedData, setUpdatedData] = useState(false);
    const [isDisable, setIsDisable] = useState(false);

    const [autoPostData, setAutoPostData] = useState({
        userId: "",
        from_channel: "",
        to_channel: "",
        id: null
    });

    const changeHandler = (e) => {
        setAutoPostData({ ...autoPostData, [e.target.name]: e.target.value });
    };

    const resetForm = () => {
        setAutoPostData({
            userId: "",
            from_channel: "",
            to_channel: "",
            id: null
        });
        setUpdatedData(false);
        setIsDisable(false);
        let data1 = {};
        dispatch(getAutoPostRow(data1));
    };

    const submitForm = (e) => {
        e.preventDefault();
        let data = {};
        data.id = autoPostData.id;
        data.uid = autoPostData.userId;
        data.from_channel = autoPostData.from_channel;
        data.to_channel = autoPostData.to_channel;
        if (isUpdatedData) {
            dispatch(updateTelegramAutoPost(data))
                .then(() => resetForm())
                .then(() => props.changeTab("showAutoPost"))
                .catch((err) => message.error('Error Inserting!'));
        } else {
            dispatch(createAutoPost(data))
                .then(() => resetForm())
                .then(() => props.changeTab("showAutoPost"))
                .catch((err) => {
                    message.error('Error Inserting!')
                });
        }
    };

    useEffect(() => {
        if (autoPostRow && autoPostRow) {
            console.log("first");
            setUpdatedData(true);
            // setDisplay(false)
            setIsDisable(!isDisable);
            setAutoPostData((prevData) => ({
                ...prevData,
                id: autoPostRow.id || "",
                userId: autoPostRow.userId || "",
                from_channel: autoPostRow.from_channel || "",
                to_channel: autoPostRow.to_channel || "",
            }));
        }
        return () => {
            dispatch(clearAutoPostRow())
        }
    }, []);

    return (
        <Paper elevation={3}>
            <form onSubmit={submitForm}>
                <Stack spacing={3} direction="column">
                    <Toolbar
                        mt={3}
                        sx={{
                            padding: "1.2rem",
                            borderBottom: "2px solid var(--primary-color)",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--primary-color)",
                                fontWeight: "bold",
                            }}
                            variant="h5"
                            id="tableTitle"
                        >
                            Add New Auto-Post
                        </Typography>
                    </Toolbar>
                    <Table
                        size="small"
                        sx={{
                            width: "auto",
                            margin: "8px !important",
                            marginTop: "16px !important",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>User ID:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        placeholder="Enter User ID"
                                        name="userId"
                                        fullWidth
                                        required
                                        value={autoPostData.userId}
                                        onChange={changeHandler}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Enter From Channel:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        placeholder="Enter From Channel"
                                        name="from_channel"
                                        fullWidth
                                        required
                                        value={autoPostData.from_channel}
                                        onChange={changeHandler}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">
                                        <b>Enter To Channel:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        placeholder="Enter To Channel"
                                        name="to_channel"
                                        fullWidth
                                        required
                                        value={autoPostData.to_channel}
                                        onChange={changeHandler}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        size="large"
                                        className="d-block mx-auto mt-4 mb-3"
                                        onClick={submitForm}
                                    >
                                        <strong>Submit</strong>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </form>
        </Paper>
    );
};

export default AddAutoPostChannel;
