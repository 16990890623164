import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Typography, Divider, Box, Tooltip, styled } from "@mui/material";
import { Popconfirm } from "antd";
import {
  fetchUrlNewDomain,
  updateUrlNewDomains,
} from "../../../redux/slices/telegramSlice";
import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux";

const UrlNewDomain = () => {
  const dispatch = useDispatch();
  const { urlNewDomains, count } = useSelector(
    (state) => state.telegram.urlNewDomains
  );

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchUrlNewDomain(1));
  }, []);

  useEffect(() => {
    dispatch(fetchUrlNewDomain(page));
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const callbackFunc = () => {
    dispatch(fetchUrlNewDomain(page));
  };

  const approve = async (value) => {
    let data = {};
    data.approved = 1;
    data.domain = value.domain;
    data.type = value.type;
    dispatch(updateUrlNewDomains(data)).then(() => {
      callbackFunc();
    });
  };

  const disApprove = (value) => {
    let data = {};
    data.approved = 0;
    data.domain = value.domain;
    data.type = value.type;
    dispatch(updateUrlNewDomains(data)).then(() => {
      callbackFunc();
    });
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      fontSize: "0.9rem",
      boxShadow: theme.shadows[3],
      padding: theme.spacing(1),
      maxWidth: "300px",
      borderRadius: "8px",
    },
    [`& .MuiTooltip-arrow`]: {
      color: theme.palette.background.paper,
    },
  }));


  const PopoverText = ({ link }) => {
    return (
      <StyledTooltip
        title={
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            {link}
          </a>
        }
      >
        <Typography
          sx={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            alignSelf: 'center',
            maxWidth: "100%",
            marginLeft: '-27px'
          }}
          component="span"
        >
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            {link}</a>
        </Typography>
      </StyledTooltip>
    );
  };


  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={3}>
          {urlNewDomains?.map((data, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <Card sx={{ height: "100%", display: "flex", flexDirection: "column", gap: '5px' }}>
                <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                  <Typography variant="body1" gutterBottom>
                    <span style={{ fontWeight: '600', fontSize: '16px' }}>Type: </span>
                    {data.type}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <span style={{ fontWeight: '600', fontSize: '16px' }}>Domain: </span>
                    {data.domain}
                  </Typography>
                  <Typography variant="body1" gutterBottom sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                    <span style={{ fontWeight: '600', fontSize: '16px' }}>Sample Link: </span>
                    <PopoverText link={data.sample_link} />
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <span style={{ fontWeight: '600', fontSize: '16px' }}>Frequency: </span>
                    {data.freq}
                  </Typography>
                  <Divider sx={{ marginY: 1 }} />
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Popconfirm
                      title="Are you sure you want to approve?"
                      onConfirm={() => approve(data)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <a
                        href="#"
                        style={{
                          padding: 5,
                          fontWeight: "bolder",
                          color: "#078101",
                          border: "1px solid #aaa",
                          borderRadius: "5px",
                          boxShadow: "2px 2px 0px 0px",
                          cursor: "pointer",
                        }}
                      >
                        Approve
                      </a>
                    </Popconfirm>
                    <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
                    <Popconfirm
                      title="Are you sure you want to disapprove?"
                      onConfirm={() => disApprove(data)}
                      okText="Yes"
                      cancelText="No"
                    // disabled={data.isActive === 0}
                    >
                      <a
                        href="#"
                        style={{
                          padding: 5,
                          fontWeight: "bolder",
                          color: "#FF1502",
                          border: "1px solid #aaa",
                          borderRadius: "5px",
                          boxShadow: "2px 2px 0px 0px",
                          cursor: "pointer",
                          // pointerEvents: data.isActive === 0 ? "none" : "auto",
                        }}
                      >
                        Disapprove
                      </a>
                    </Popconfirm>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {
        (count / 100) > 1 ?
          <Pagination
            style={{ display: 'flex', flexDirection: 'row-reverse' }}
            count={Math.ceil(count / 100)}
            page={page}
            onChange={handleChange}
            color="primary"
          /> : null
      }
    </>
  );
};
export default UrlNewDomain;
