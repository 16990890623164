import React from "react";
import { PrivateRoutes, LoginRoutes } from "../context/authContext";
import { Routes, Route } from "react-router-dom";
import Login from "../components/auth/index"; //testing
import Layout from "../layout";
import AddTestimonials from "../components/Testimonials/AddTestimonials";
import PaymentRedemption from "../components/PaymentRedemption";

////managestore
import CreateShowStore from "../components/ManageStores/CreateShowStore";
import StoreDescription from "../components/ManageStores/StoreDescription";
import AdditionalStoreData from "../components/ManageStores/AdditionalStoreData";
import Deeplink from "../components/ManageStores/Deeplink";
import StoreSchema from "../components/ManageStores/Store_Schema";
import AddCategory from "../components/ManageStores/Category/AddCategory/index";

///affiliate

import AffiliateLinking from "../components/Affiliate/AffiliateLinking";
import MasterPartners from "../components/Affiliate/MasterPartners";
import StorePartners from "../components/Affiliate/StorePartners";
import UploadCSV from "../components/PointerPoster/UploadCSV";
import PointMaster from "../components/PointerPoster/PointMaster";
import ResponseMaster from "../components/PointerPoster/ResponseMaster";
import ApprovalMaster from "../components/PointerPoster/ApprovalMaster";
import SearchByClickId from "../components/crm/ClickIdOperations/searchByClickId";
import AllOrdersCRm from "../components/crm/AllOrdersCrmData";
import ExtensionStoreDomain from "../components/ManageStores/extensionStoreDomain";
import FeaturedStores from "../components/ManageStores/featuredStores/index.js";

/// Deals
import Deals from "../components/Deals";
import TaskReminder from "../components/TaskReminder/index";
import Voucher from "../components/PaymentRedemption/Voucher";

/////rtk
import Broken from "../components/ManageStores/Broken_Links/index.js";
import Dashboard from "../components/dashboard";
import Profile from "../components/profile";
import Approve from "../components/profile/adminMenu/Approvals/index.js";
import RoleModules from "../components/profile/adminMenu/Modules/Role Module/index.js";
import ViewModules from "../components/profile/adminMenu/Modules/View Module/index.js";
import ShowTestimonials from "../components/Testimonials/ShowTestimonials";
import Email from "../components/Email";
import ShowContactedUsers from "../components/ContactedUsers";
import CRM from "../components/crm";
import ShowImages from "../components/Gallery/ShowImages";
import AddImages from "../components/Gallery/AddImages/index";
import AddNews from "../components/News/AddNews";
import ShowNews from "../components/News/ShowNews";
import UserRoleManagement from "../components/userRoleManagement";
import UserDetails from "../components/usersDetails";
import LiveBanners from "../components/ManageBanners/LiveBanners";
import LiveOffers from "../components/ManageBanners/LiveOffers";
import UploadImage from "../components/ManageBanners/UploadImage";
import AddNewActivity from "../components/Activities/AddNewActivity";
import ShowActivities from "../components/Activities/ShowActivities";
import ManualBonusPoints from "../components/ManualBonusPoints";
import Redemption from "../components/Redemption";
import Category from "../components/ManageStores/Category";
import Signup from "../components/auth/signup";
import HeaderFooter from "../components/HeaderFooter/Index";
// import PaymentStatus from "../components/PaymentRedemption/index.js";
import ManualPayments from "../components/PaymentRedemption/ManualPayment";
import UserSetting from "../components/Telegram/UserSetting/index";
import AutoPostFromChannel from "../components/Telegram/AutopostFromChannel/index";
import TelegramBotUsers from "../components/Telegram/TelegramBotUsers/index";
import UrlNewDomain from "../components/Telegram/UrlNewDomain/index";
import UserChannelMapping from "../components/Telegram/UserChannelMapping/index";

function AllRoute() {
  return (
    <Routes>
      {/* Authenticate Routes */}
      <Route element={<LoginRoutes />}>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
      </Route>

      {/* Proteted Routes */}
      <Route
        element={
          <Layout>
            <PrivateRoutes />
          </Layout>
        }
      >
        {/* Dashboard */}
        <Route path="/" element={<Dashboard />} />

        {/* Profile */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/changepassword" element={<Profile />} />
        <Route path="/admin/approve" element={<Approve />} />
        <Route path="/admin/role-modules" element={<RoleModules />} />
        <Route path="/admin/modules" element={<ViewModules />} />

        {/* UserManagement */}
        <Route path="/usermanagement" element={<UserRoleManagement />} />

        {/* Activites */}
        <Route path="/activities/addnewactivity" element={<AddNewActivity />} />
        <Route path="/activities/showactivities" element={<ShowActivities />} />
        <Route path="/activities/showactivities" element={<ShowActivities />} />

        {/* Affiliate */}
        {/* <Route path="/affiliate/:name?" element={<Affiliate />} /> */}
        <Route path="/affiliate/afflinking" element={<AffiliateLinking />} />
        <Route path="/affiliate/masterpartners" element={<MasterPartners />} />
        <Route path="/affiliate/storepartners" element={<StorePartners />} />
        {/* <Route path="/affiliate/addpartner" element={<AddPartners />} /> */}

        {/* PointerPoster */}
        {/* <Route path="/points/:name?" element={<PointerPoster />} /> */}
        <Route path="/points/responsemaster" element={<ResponseMaster />} />
        <Route path="/points/pointmaster" element={<PointMaster />} />

        {/* Upload CSV */}
        <Route path="/points/uploadcsv" element={<UploadCSV />} />

        <Route path="/points/approvalmaster" element={<ApprovalMaster />} />

        {/* Gallery */}
        {/* testing for later usage - bharat */}
        {/* <Route path="/gallery/a" element={<Abc />} />  */}
        <Route path="/gallery/addimages" element={<AddImages />} />
        <Route path="/gallery/showimages" element={<ShowImages />} />

        {/* ManageBannerOffer */}
        <Route path="/managebanners/uploadimage" element={<UploadImage />} />
        <Route path="/managebanners/livebanners" element={<LiveBanners />} />
        <Route path="/managebanners/liveoffers" element={<LiveOffers />} />

        {/* Testimonials */}
        <Route
          path="/testimonials/addtestimonials"
          element={<AddTestimonials />}
        />
        <Route
          path="/testimonials/showtestimonials"
          element={<ShowTestimonials />}
        />
        <Route path="/news/addnews" element={<AddNews />} />
        <Route path="/news/shownews" element={<ShowNews />} />

        {/* Payment */}
        <Route path="/redeem/:type?" element={<ShowNews />} />
        <Route path="/bonus/bonuspoints" element={<ManualBonusPoints />} />
        <Route path="/redeem/vouchers" element={<Voucher />} />
        <Route path="/redeem/paymentstatus" element={<PaymentRedemption />} />
        <Route path="/redeem/manualpayment" element={<ManualPayments />} />

        {/* ManageStore */}
        {/* <Route path="/managestores/:type?" element={<ManageStores />} /> */}
        {/* <Route path="/news/shownews" element={<ShowNews />} /> */}
        <Route
          path="/managestores/storedescription"
          element={<StoreDescription />}
        />
        <Route
          path="/managestores/showextstoredom"
          element={<ExtensionStoreDomain />}
        />
        <Route
          path="/managestores/featurestores"
          element={<FeaturedStores />}
        />
        <Route path="/managestores/createStore" element={<CreateShowStore />} />
        <Route
          path="/managestores/additionalstoredata"
          element={<AdditionalStoreData />}
        />
        <Route path="/managestores/deeplink" element={<Deeplink />} />
        <Route path="/managestores/storeschema" element={<StoreSchema />} />
        <Route path="/managestores/categorypage" element={<AddCategory />} />
        <Route path="/managestores/category" element={<Category />} />
        <Route
          path="/managestores/redirect-store-broken"
          element={<Broken />}
        />
        {/* HeaderFooter */}
        {/* <Route path="/headerfooter/:type?" element={<HeaderFooter />} /> */}
        {/* <Route path="/headerfooter/showheaderfoote" element={<ShowHeaderFooter />} /> */}
        {/* <Route path="/headerfooter/addheaderfooter" element={<AddHeaderFooter />} /> */}
        <Route
          path="/headerfooter/addheaderfooter"
          element={<HeaderFooter />}
        />

        {/* ContactUser */}
        <Route path="/contactedusers" element={<ShowContactedUsers />} />

        {/* Redemption */}
        {/* <Route path="/redemption/:type?" element={<Redemption />} />   */}
        <Route path="/redemption/addredeem" element={<Redemption />} />

        {/* Email */}
        <Route path="/email/:type?" element={<Email />} />

        {/* CRM */}
        <Route path="/crm/:type?" element={<CRM />} />
        <Route path="/user/:type?" element={<UserDetails />} />
        <Route path="/crm/searchbyclickid" element={<SearchByClickId />} />
        <Route path="/crm/allordersdata" element={<AllOrdersCRm />} />
        <Route path="/crm/top10data" element={<CRM />} />

        {/* Deals */}
        <Route path="/deals/createdeals" element={<Deals />} />

        {/* TaskReminder */}
        <Route path="/taskreminder" element={<TaskReminder />} />

        {/* Telegram */}
        <Route path="/telegram/user-setting" element={<UserSetting />} />
        <Route
          path="/telegram/autopost-from-channel"
          element={<AutoPostFromChannel />}
        />
        <Route path="/telegram/bot-users" element={<TelegramBotUsers />} />
        <Route path="/telegram/url-new-domains" element={<UrlNewDomain />} />
        <Route
          path="/telegram/urlchannelmapping"
          element={<UserChannelMapping />}
        />
      </Route>
    </Routes>
  );
}
export default AllRoute;
