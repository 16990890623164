import axios from "axios";
import AppConstants from "../../constants/appConstants";

const API = axios.create({
  baseURL: `${AppConstants.baseURL}`,
});

export const login = (loginData) => API.post("/auth/login", loginData);
export const signupApi = (signupData) => API.post("/auth/signup", signupData);
export const logout = () => API.get("/auth/logout");
export const getProfile = () => API.get("/auth/profile");
export const showRolesApi = () => API.get("/auth/showroles");
export const showAllModules = () => API.get("/auth/showallmodules")
export const getAllPanelUsers = () => API.get("/auth/allpanelusers");
export const getAllUsers = () => API.get("/auth/getallusers");
export const changePassword = (data) => API.post("/auth/changepassword", data);
export const addNewModules = (formData) => API.post("/auth/newmodules", formData);
export const activateModules = (formData) => API.post("/auth/activemodules", formData);
export const modulePermissions = (formData) => API.post("/auth/updatePredefinedAndUserPermission", formData);
export const userManagementRolesData = (formData) => API.post("/auth/userallpermission", formData);
export const updatePanelUser = (formData) => API.post("/auth/updatepanelusers", formData);
