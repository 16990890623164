import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Divider } from 'antd';
import { fetchAutoPost, updateTelegramAutoPost, getAutoPostRow } from "../../../redux/slices/telegramSlice";
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from "react-router-dom";


function dateConverter(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    let year = date.getFullYear();
    return `${year}-${mnth}-${day}`
};

const ShowAutoPost = (props) => {
    const dispatch = useDispatch();
    const { autoPost, count } = useSelector(state => state.telegram.autoPost);

    const [page, setPage] = useState(1)

    useEffect(() => {
        dispatch(fetchAutoPost(1));
    }, []);

    useEffect(() => {
        dispatch(fetchAutoPost(page));
    }, [page]);

    const edit = (e, value) => {
        let data = {};
        data.id = value[0]
        data.userId = value[4];
        data.from_channel = value[5];
        data.to_channel = value[6];
        dispatch(getAutoPostRow(data));
        props.changeTab("addAutoPost")
    }
    const cancel = () => {
        //console.log("Cancelled");
    }

    const callbackFunc = () => {
        dispatch(fetchAutoPost(page));
    }
    const activated = async (e, value) => {
        let data = {};
        data.id = value[0];
        data.active = 1;
        data.uid = value[4]
        dispatch(updateTelegramAutoPost(data))
            .then(() => {
                callbackFunc();
            })
    }
    const deactivated = (e, value) => {
        let data = {};
        data.id = value[0];
        data.active = 0;
        data.uid = value[4]
        dispatch(updateTelegramAutoPost(data))
            .then(() => {
                callbackFunc();
            })
    }

    const columns = [
        {
            name: "id",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
        {

            name: "Action",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const isActive = tableMeta.rowData[7];
                    return (
                        <div style={{ display: 'flex' }}>
                            <Popconfirm
                                title="Are you sure you want to activate this auto-post?"
                                onConfirm={e => activated(e, tableMeta.rowData)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                disabled={isActive === 1}
                            >
                                <a
                                    href="#"
                                    style={{
                                        padding: 5,
                                        fontWeight: "bolder",
                                        color: isActive === 1 ? "#aaa" : "#078101",
                                        border: "1px solid #aaa",
                                        borderRadius: "5px",
                                        boxShadow: "2px 2px 0px 0px",
                                        cursor: isActive === 1 ? "not-allowed" : "pointer",
                                        pointerEvents: isActive === 1 ? "none" : "auto"
                                    }}
                                >
                                    Activate
                                </a>
                            </Popconfirm>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="Are you sure you want to deactivate this auto-post?"
                                onConfirm={e => deactivated(e, tableMeta.rowData)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                disabled={isActive === 0}
                            >
                                <a
                                    href="#"
                                    style={{
                                        padding: 5,
                                        fontWeight: "bolder",
                                        color: isActive === 0 ? "#aaa" : "#FF1502",
                                        border: "1px solid #aaa",
                                        borderRadius: "5px",
                                        boxShadow: "2px 2px 0px 0px",
                                        cursor: isActive === 0 ? "not-allowed" : "pointer",
                                        pointerEvents: isActive === 0 ? "none" : "auto"
                                    }}
                                >
                                    Deactivate
                                </a>
                            </Popconfirm>
                        </div>
                    );
                }
            }
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) =>
                (
                    < div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Popconfirm
                            title="Edit this auto-post?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div >
                )
            }
        },
        {
            name: "name",
            label: "Username",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "uid",
            label: "User ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "from_channel",
            label: "From Channel",
            options: {
                filter: false,
                sort: false,
            }
        },

        {
            name: "to_channel",
            label: "To Channel",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "inserted_at",
            label: "Created",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => <p>{dateConverter(value)}</p>
            }
        }
    ];

    const options = {
        filterType: 'store_name',
        selectableRows: 'none',
        rowsPerPage: 100,
        rowsPerPageOptions: [100],
        page: page - 1,
        onChangePage: (currentPage) => {
            setPage(currentPage + 1);
        },
        count,
        pagination: true,
        serverSide: true,
    };

    return (
        <>
            <MUIDataTable
                title={"Show Auto-Post Channels"}
                data={autoPost && autoPost}
                columns={columns}
                options={options}
            />
        </>
    );
}
export default ShowAutoPost;