import React, { Component, useState } from "react";
import { Routes, Route, useHistory, NavLink } from "react-router-dom";
import styled from "styled-components";
// import { Icon } from "antd";
import { Link } from "react-router-dom";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import AddRoles from "./addNewRole";
import Modules from "./Modules";

const Wrapper = styled.div`
  width: 100%;
`;

const RightHeader = styled.div`
  height: 15%;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px 15px 0px 0px;
  > p {
    font-size: 22px;
    font-weight: bolder;
    color: #000;
    margin-bottom: 0px;
  }
`;

const RightInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const RightInfoKeyData = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #bbb;
  > p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #000;
  }
`;

const RightInfoValueContainer = styled.div`
  width: 100%;
  background: #eee;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const ActionButton = styled.div`
  width: 100px;
  padding: 8px;
  margin-top: 10px;
  background: ${(props) => props.color && props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  > p {
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
    font-weight: bolder;
  }
`;
const MODE = {
  ROLES: "ROLES",
  PERMISSIONS: "PERMISSIONS",
  USERS: "USERS",
  MODULES: "MODULES",
};

const AdminMenu = (props) => {
  const [mode, setMode] = useState("");

  // const navigate=useHistory();

  //   const iconPlusClickHandler = data => {
  //     setMode(data);
  //   };

  //  const iconMinusClickHandler = () => {
  //   setMode(data);
  //   };
  const { user } = props;

  // const addNewRoles =()=>{
  //   console.log("addRoles");
  //   // navigate("/profile/adminMenu/addNewRole")
  // }
  return (
    <Wrapper>
      <RightHeader>
        <p>Role Permissions</p>
      </RightHeader>

      <RightInfoContainer>
        <RightInfoKeyData
          onClick={() => {
            props.setTab("addroles");
          }}
          style={{ cursor: "pointer" }}
        >
          <p>Add New Roles</p>
        </RightInfoKeyData>
      </RightInfoContainer>

      <RightInfoContainer
        onClick={() => props.setTab("rolewisepermission")}
        style={{ cursor: "pointer" }}
      >
        {" "}
        {/* is not built yet*/}
        <RightInfoKeyData>
          <p>Role Wise Permissions</p>
        </RightInfoKeyData>
      </RightInfoContainer>

      <Modules />

      <RightInfoContainer
        style={{ cursor: "pointer" }}
      >
        {" "}
        {/* is not built yet*/}
        <NavLink to="/admin/approve">
          <RightInfoKeyData>
            <p>Approvals</p>
          </RightInfoKeyData>
        </NavLink>
      </RightInfoContainer>


      {/* <RightInfoContainer>
      <Link to={"/admin/alluser"}>
        <RightInfoKeyData>
          <p>Change Role</p>
        </RightInfoKeyData>
      </Link>
    </RightInfoContainer> */}
    </Wrapper>

    // setshowAddRoles ? (<AddRoles />) : ();
  );
};

export default AdminMenu;
