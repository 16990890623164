import axios from "axios";
import AppConstants from "../../constants/appConstants";


const API = axios.create({
    baseURL: `${AppConstants.baseURL}`,
});

export const modulesByUserData = (queryString) => API.get(`/auth/modulesbyuser?${queryString}`)
export const getStoreData = () => API.get("/managestores/getallstores");
export const getBrokenLinksCount = () => API.get("/managestores/getBrokenLinksCount")