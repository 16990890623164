import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { fetchUserIdMapping } from "../../../redux/slices/telegramSlice"
import { useDispatch, useSelector } from 'react-redux';

function UserChannelMapping() {

  const dispatch = useDispatch();
  const { UserIdData, count } = useSelector(state => state.telegram.UserIdData);

  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(fetchUserIdMapping(1));
  }, []);

  useEffect(() => {
    dispatch(fetchUserIdMapping(page));
  }, [page]);

  const columns = [
    {
      name: "uid",
      label: "User Name",
    },
    {
      name: "cid",
      label: "Channel ID",
    },
    {
      name: "channel_name",
      label: "Channel Name",
    },
    {
      name: "channel_link",
      label: "Channel Link",
    },
    {
      name: "HyBot",
      label: "HyBot",
    },

    {
      name: "active",
      label: "Active",
    },

  ];

  const options = {
    selectableRows: 'none',
    rowsPerPage: 100,
    rowsPerPageOptions: [100],
    page: page - 1,
    onChangePage: (currentPage) => {
      setPage(currentPage + 1);
    },
    count,
    pagination: true,
    serverSide: true,
    search: true,
    viewColumns: true,
    enableNestedDataAccess: ".",
  }

  return (
    <>
      <MUIDataTable
        title={"User Channel Mapping"}
        data={UserIdData && UserIdData}
        columns={columns}
        options={options}
      />

    </>
  )

}

export default UserChannelMapping;
