import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography } from 'antd';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { gotDescriptionRow, showDescription } from "../../../../redux/slices/manageStoreSlice";
import { Tooltip } from "@mui/material";


const ShowDescription = (props) => {
    const [storesData, setStoresData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { descriptionData } = useSelector((s) => s.manageStores);
    // console.log(data);


    const confirm = (e, value) => {
        let data = {};
        data.store_id = value[2];
        data.store_name = value[3]
        data.seo_title = value[5];
        data.seo_description = value[6];
        data.h1_title = value[7];
        data.related_description = value[8];
        // data.store_schema=value[9]
        data.d_type = value[9];
        data.description = value[10];
        // data.category_name = value[11];
        dispatch(gotDescriptionRow(data));
        props.setDisplayType();
    }
    const cancel = () => {
        //console.log("Cancelled");
    }

    useEffect(() => {
        dispatch(showDescription());
        var stores_data = JSON.parse(localStorage.getItem("stores"));

        if (stores_data) {
            setStoresData(stores_data);
        }

    }, []);

    const columns = [
        {
            name: "Action",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div>
                        <Popconfirm
                            title="Are you sure delete this task?"
                            onConfirm={e => confirm(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Deactivate</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => confirm(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div>
                )
            }
        },
        {
            name: "store_id",
            label: "STore ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "type",
            label: "Type",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "seo_title",
            label: "SEO Title",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "seo_description",
            label: "SEO Description",
            options: {
                customBodyRender: (desc) => {
                    return (
                        <Tooltip title={desc}>
                            <p
                                style={{
                                    margin: 0,
                                    width: "200px",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "pre-wrap" // This ensures that line breaks in the description are respected
                                }}
                            >
                                {desc}
                            </p>
                        </Tooltip>
                    );
                },
                filter: true,
                sort: true,
            }
        },
        {
            name: "h1_title",
            label: "H1 Title",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "related_description",
            label: "Related Description",
            options: {
                customBodyRender: (desc) => {
                    return (
                        <Tooltip title={desc}>
                            <p
                                style={{
                                    margin: 0,
                                    width: "200px",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "pre-wrap" // This ensures that line breaks in the description are respected
                                }}
                            >
                                {desc}
                            </p>
                        </Tooltip>
                    );
                },
                filter: true,
                sort: false,
            }
        },
        // {
        //     name: "store_schema",
        //     label: "Store Schema",
        //     options: {
        //         customBodyRender: (value, tableMeta, updateValue) => <div dangerouslySetInnerHTML={{
        //             __html: value
        //           }}></div>
        //     }
        // },
        {
            name: "d_type",
            label: "D Type",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "description_editor",
            label: "Description Editor",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => <div style={{
                    margin: 0,
                    width: "300px",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-wrap" // This ensures that line breaks in the description are respected
                }} dangerouslySetInnerHTML={{
                    __html: value
                }}></div>
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "created",
            label: "Created",
            options: {
                filter: true,
                sort: false,
            }
        }
    ];
    const options = {
        filterType: 'store_name',
    };
    return (
        <Fragment>
            <MUIDataTable
                title={"Show Description (search by store_name)"}
                data={descriptionData && descriptionData}
                columns={columns}
                options={options}
            />
        </Fragment>
    );
}
export default ShowDescription;