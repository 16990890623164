import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, message, Divider } from 'antd';
import queryString from 'query-string';
import { showFeatureStore, activatedFeaturedStoresData, gotFeaturedStoreDataRow } from "../../../redux/slices/manageStoreSlice";
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from "react-router-dom";


function dateConverter(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    let year = date.getFullYear();
    return `${year}-${mnth}-${day}`
};


const ShowFeaturedStore = (props) => {
    const dispatch = useDispatch();
    const { featuresdata } = useSelector(state => state.manageStores);
    useEffect(() => {
        dispatch(showFeatureStore());
    }, []);

    const edit = (e, value) => {
        let data = {};
        data.store_id = value[2];
        data.store_name = value[3];
        data.rating = value[4];
        dispatch(gotFeaturedStoreDataRow(data));
        props.changeTab("addFeaturedStore")
    }
    const cancel = () => {
        //console.log("Cancelled");
    }

    const callbackFunc = () => {
        dispatch(showFeatureStore());
    }
    const activated = async (e, value) => {
        let data = {};
        data.store_id = value[2];
        data.type = "activate";
        let query = queryString.stringify(data);
        dispatch(activatedFeaturedStoresData(query));
        callbackFunc();
        // message.success("Store data Activated Successfully");
    }
    const deactivated = (e, value) => {
        let data = {};
        data.store_id = value[2];
        data.type = "deactivate";
        let query = queryString.stringify(data);
        dispatch(activatedFeaturedStoresData(query));
        callbackFunc();
        // message.success("Store data Deactivated Successfully");
    }

    const columns = [
        {

            name: "Action",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const isActive = tableMeta.rowData[5];
                    return (
                        <div style={{ display: 'flex' }}>
                            <Popconfirm
                                title="activate the activity"
                                onConfirm={e => activated(e, tableMeta.rowData)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                disabled={isActive === 1}
                            >
                                <a href="#" style={{
                                    padding: 5, fontWeight: "bolder", color: "#078101", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", cursor: isActive === 1 ? "not-allowed" : "pointer",
                                    pointerEvents: isActive === 1 ? "none" : "auto", color: isActive === 1 ? "#aaa" : "#078101",
                                }}>Activate</a>
                            </Popconfirm>
                            <Divider type="vertical"></Divider>
                            <Popconfirm
                                title="Deactivated"
                                onConfirm={e => deactivated(e, tableMeta.rowData)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                disabled={isActive === 0}
                            >
                                <a href="#" style={{
                                    padding: 5, fontWeight: "bolder", color: "#FF1502", border: "1px solid #aaa", borderRadius: "5px", boxShadow: "2px 2px 0px 0px", cursor: "pointer", cursor: isActive === 0 ? "not-allowed" : "pointer",
                                    pointerEvents: isActive === 0 ? "none" : "auto", color: isActive === 0 ? "#aaa" : "#FF1502",
                                }}>Deactivate</a>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        },
        {
            name: "opertaion",
            label: "Operation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) =>
                (
                    < div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Popconfirm
                            title="Edit this task?"
                            onConfirm={e => edit(e, tableMeta.rowData)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Edit</a>
                        </Popconfirm>
                    </div >
                )
            }
        },
        {
            name: "store_id",
            label: "Store ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "store_name",
            label: "Store Name",
            options: {
                filter: false,
                sort: false,
            }
        },

        {
            name: "rating",
            label: "Priority",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "created_at",
            label: "Created",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => <p>{dateConverter(value)}</p>
            }

        }
    ];

    const options = {
        filterType: 'store_name',
    };

    return (
        <>
            <MUIDataTable
                title={"Show Featured Stores  (search by store Name)"}
                data={featuresdata && featuresdata}
                columns={columns}
                options={options}
            />
        </>
    );
}
export default ShowFeaturedStore;